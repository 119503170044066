 <template>
 
 	<el-row style="min-width: 1200px;">
 
 		<el-card class="box-card">
 			<div slot="header" class="clearfix">
 				<span>登录日志</span>
 			</div>
 
 			<div>
 
 				<el-alert :closable="false" style="margin-bottom: 10px;" title="您的账户登录日志, 如发现登录异常请及时更改账户密码!"
 				 type="info" show-icon>
 				</el-alert>
 
 
 				<div>
 					<el-table :data="data.data" height="600">
 						<template slot="empty">
 							<el-empty :image-size="200" description="暂未查询到您的登录数据哦!"></el-empty>
 						</template>
                        <el-table-column type="index" label="ID"></el-table-column>
 						<!-- <el-table-column prop="id" label="ID"  width="100"></el-table-column> -->
 						<el-table-column prop="user_id.username" label="所属用户" align="center"></el-table-column>
 						<el-table-column prop="user_agent" label="登录设备" align="center" width="800">
 						</el-table-column>
 						<el-table-column prop="ip" label="登录IP" align="center">
 						</el-table-column>
 					
 						<el-table-column prop="w_state" label="状态" align="center">
 							<template slot-scope="scope">
 								<el-tag size="mini" type="success" v-if="scope.row.w_state==1">{{ scope.row.state }}</el-tag>
 								<el-tag size="mini" v-else-if="scope.row.w_state==2" type="danger">{{ scope.row.state }}</el-tag>
 								<el-tag size="mini" v-else type="warning">{{ scope.row.state }}</el-tag>
 							</template>
 						</el-table-column>
 					
 						<el-table-column prop="username" label="输入账号" width="150" align="center">
 						</el-table-column>
 						
 						<el-table-column prop="create_time" label="记录时间" width="150" align="center">
 						</el-table-column>
 					
 					</el-table>
 					
 					<el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="this.params.page"
 					 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next" :total="data.total" style="margin-top:15px; margin-bottom: 15px; position: relative;">
 					</el-pagination>
 				</div>
 
 
 			</div>
 		</el-card>
 
 
 	</el-row>
 
 </template>
 
 <script>
 	import HTTP from '@/request/api'
 
 	export default {
 		data() {
 			return {
 				params: {
 					page: 1,
 					limit: 10
 				},
				data: []
 			}
 		},
 		created() {
 
 		},
 		mounted() {
			this.GetLogLogList()
 		},
 		methods: {
			// 获取登录日志列表
			GetLogLogList: function() {
				HTTP.userLoginLog(this.params).then(res => {
					this.data = res
				})
			},
			
			handleSizeChange(value) {
				this.params.limit = value
				this.GetLogLogList()
			},
			handleCurrentChange(value) {
				this.params.page = value
				this.GetLogLogList()
			},
 
 		}
 	}
 </script>
 
 <style scoped>
 	.el-row {
 		text-align: left;
 	}
 
 	.user-box {
 		background: #fff;
 		height: 100px;
 		padding: 20px;
 		margin-bottom: 20px;
 		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
 	}
 
 	.el-row {
 		margin-bottom: 20px;
 
 		&:last-child {
 			margin-bottom: 0;
 		}
 	}
 
 	.box-card {
 		box-sizing: border-box;
 		margin: 0;
 		padding: 0;
 		color: #000000d9;
 		font-size: 14px;
 		font-variant: tabular-nums;
 		line-height: 1.5715;
 		list-style: none;
 		font-feature-settings: "tnum";
 		position: relative;
 		background: #fff;
 		border-radius: 10px;
 	}
 
 	/deep/.dialog-box-card {
 		box-sizing: border-box;
 		margin: 0;
 		padding: 0;
 		color: #000000d9;
 		font-size: 14px;
 		font-variant: tabular-nums;
 		line-height: 1.5715;
 		list-style: none;
 		font-feature-settings: "tnum";
 		position: relative;
 		background: #fff;
 		border-radius: 10px;
 		min-width: 520px;
 		max-width: 520px;
 		margin: 0 auto;
 	}
 
 	/deep/ .el-dialog__header {
 		padding: 16px 24px;
 		color: #000000d9;
 		border-bottom: 1px solid #f0f0f0;
 	}
 </style>
 