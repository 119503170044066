<template>

    <el-container class="body">

        <el-header>
            <!-- 09f -->
            <!-- 545c64 -->
            <el-menu style="display: flex; align-items: center;" :default-active="$route.path" unique-opened class="el-menu-demo"
                mode="horizontal" background-color="#545c64" text-color="#fff" router active-text-color="#09f">

                <div style="max-height: 60px; align-items: center;">
                    <el-image style="width: 150px; height: 50px; border: 0px; margin-right: 10px;" :src="logo_url" fit="fit"></el-image>
                </div>
                <el-menu-item index="/user/dashboard">
                    <i class="el-icon-setting"></i>
                    <span slot="title">工作台</span>
                </el-menu-item>

                <el-menu-item index="/user/video">
                    <i class="el-icon-video-camera-solid"></i>
                    <span slot="title">视频翻译</span>
                </el-menu-item>

                <el-menu-item index="/user/photo">
                    <i class="el-icon-picture-outline"></i>
                    <span slot="title">图片翻译</span>
                </el-menu-item>
                
                <el-menu-item index="/user/peiyin">
                    <i class="el-icon-microphone"></i>
                    <span slot="title">AI配音</span>
                </el-menu-item>

                <el-menu-item index="/user/recharge">
                    <i class="el-icon-bank-card"></i>
                    <span slot="title">账变记录</span>
                </el-menu-item>
                <el-menu-item index="/user/loginlog">
                    <i class="el-icon-map-location"></i>
                    <span slot="title">登录日志</span>
                </el-menu-item>
                <el-menu-item index="/user/pay">
                    <i class="el-icon-goods"></i>
                    <span slot="title">充值中心</span>
                </el-menu-item>
                <el-menu-item index="/user/apple">
                    <i class="el-icon-apple"></i>
                    <span slot="title">AppleID</span>
                </el-menu-item>

                <!-- <el-menu-item>
                    <i class="el-icon-upload"></i>
                    <span @click="ViewMessage('正在研发中，敬请期待。')" slot="title">DY批量入库</span>
                </el-menu-item> -->

            </el-menu>

            <div style="align-items: center; display: flex;">
                <el-button icon="el-icon-shopping-cart-full" type="primary" size="mini" @click="ai_charge=true">购买AI配音</el-button>
                <el-button icon="el-icon-shopping-cart-full" type="warning" size="mini" @click="charge=true">翻译充值</el-button>

                <el-dropdown style="display: flex; padding: 0px 10px;" @command="handleCommand">
                    <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
                    <el-dropdown-menu slot="dropdown" style="margin-top: 0px;">
                        <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>

        </el-header>


        <el-drawer title="翻译额度充值" :visible.sync="charge" size="60%">

            <div style="padding: 0 30px;">

                <el-tabs v-model="activeName">

                    <el-tab-pane name="tc">
                        <span slot="label"><i class="el-icon-date"></i> 套餐</span>
                        <div>

                            <el-tabs tab-position="left">
                                <el-tab-pane label="视频翻译">
                                    
                                    <span slot="label"><i class="el-icon-video-camera-solid"></i> 视频翻译</span>
                                        
                                    <el-table :data="package" height="650">
                                        <template slot="empty">
                                            <el-empty :image-size="200" description="暂未查询到视频翻译套餐哦!"></el-empty>
                                        </template>
                                        <el-table-column type="index"></el-table-column>
                                        <el-table-column prop="name" label="套餐" align="center"></el-table-column>
                                        <el-table-column prop="price" label="价格" align="center">
                                        </el-table-column>
                                        <el-table-column prop="package_num" label="次数" align="center">
                                        </el-table-column>
                                    
                                        <el-table-column prop="desc" label="活动" align="center">
                                        </el-table-column>
                                    
                                        <el-table-column label="操作" width="200" align="center">
                                    
                                            <template slot="header" slot-scope="scope">
                                                <el-button type="primary" icon="el-icon-refresh" size="mini" @click="GetVideoPackage">刷新</el-button>
                                            </template>
                                    
                                            <template slot-scope="scope">
                                                <el-button size="mini" @click="BuyPackage(scope.row.id, 'video')">购买</el-button>
                                            </template>
                                        </el-table-column>
                                    
                                    </el-table>
                                </el-tab-pane>
                                <el-tab-pane label="图片翻译">
                                    <span slot="label"><i class="el-icon-picture-outline"></i> 图片翻译</span>
                                    <el-table :data="package_photo" height="650">
                                        <template slot="empty">
                                            <el-empty :image-size="200" description="暂未查询到图片翻译套餐信息哦!"></el-empty>
                                        </template>
                                        <el-table-column type="index"></el-table-column>
                                        <el-table-column prop="name" label="套餐" align="center"></el-table-column>
                                        <el-table-column prop="price" label="价格" align="center">
                                        </el-table-column>
                                        <el-table-column prop="package_num" label="次数" align="center">
                                        </el-table-column>
                                    
                                        <el-table-column prop="desc" label="活动" align="center">
                                        </el-table-column>
                                    
                                        <el-table-column label="操作" width="200" align="center">
                                    
                                            <template slot="header" slot-scope="scope">
                                                <el-button type="primary" icon="el-icon-refresh" size="mini" @click="GetPhotoPackage">刷新</el-button>
                                            </template>
                                    
                                            <template slot-scope="scope">
                                                <el-button size="mini" @click="BuyPackage(scope.row.id, 'photo')">购买</el-button>
                                            </template>
                                        </el-table-column>
                                    
                                    </el-table>
                                </el-tab-pane>
                            </el-tabs>




                            

                        </div>
                    </el-tab-pane>



                    <el-tab-pane>
                        <span slot="label"><i class="el-icon-date"></i> 充值记录</span>
                        <div>
                            <el-table :data="packageRecord.data" height="650">
                                <template slot="empty">
                                    <el-empty :image-size="200" description="暂未查询到发布的任务数据哦!"></el-empty>
                                </template>
                                <el-table-column type="index"></el-table-column>
                                <el-table-column prop="create_time" label="购买时间" align="center" width="180"></el-table-column>
                                <el-table-column prop="package_type.package_type" label="类型" align="center"></el-table-column>
                                <el-table-column prop="buy_num" label="购买次数" align="center">
                                </el-table-column>
                                <el-table-column prop="remaining_num" label="剩余次数" align="center">
                                </el-table-column>

                                <el-table-column prop="desc" label="活动" align="center">
                                </el-table-column>

                                <el-table-column prop="validity_start" label="有效期开始" align="center">
                                </el-table-column>

                                <el-table-column label="有效期结束" prop="validity_end" width="200" align="center">
                                    <template slot="header" slot-scope="scope">
                                        <el-button type="primary" icon="el-icon-refresh" size="mini" @click="GetPackageVideoRecord">刷新</el-button>
                                    </template>
                                    <el-table-column prop="validity_end" label="有效期结束" width="200" align="center">

                                    </el-table-column>
                                </el-table-column>


                            </el-table>
                        </div>
                        
                        <div style="padding: 15px 0;">
                            <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                :current-page="this.package_record_params.page" :page-sizes="[10, 20, 50, 100]" :page-size="10"
                                layout="total, prev, pager, next" :total="packageRecord.total" style="margin-top:15px; margin-bottom: 15px; position: relative;">
                            </el-pagination>
                        </div>
                    </el-tab-pane>

                </el-tabs>


            </div>



        </el-drawer>
        
        
        
        <el-drawer title="AI配音套餐购买" :visible.sync="ai_charge" size="60%">
        
            <div style="padding: 0 30px;">
        
                <el-table :data="package_ai" height="650">
                    <template slot="empty">
                        <el-empty :image-size="200" description="暂未查询到视频翻译套餐哦!"></el-empty>
                    </template>
                    <el-table-column type="index"></el-table-column>
                    <el-table-column prop="name" label="套餐" align="center"></el-table-column>
                    <el-table-column prop="desc" label="介绍" align="center"></el-table-column>
                    <el-table-column prop="price" label="价格" align="center">
                    </el-table-column>
                    <el-table-column prop="time" label="时间" align="center">
                    </el-table-column>
                
                    <el-table-column prop="time_type" label="时间类型" align="center">
                    </el-table-column>
                
                    <el-table-column label="操作" width="200" align="center">
                
                        <template slot="header" slot-scope="scope">
                            <el-button type="primary" icon="el-icon-refresh" size="mini" @click="GetAiPackage()">刷新</el-button>
                        </template>
                
                        <template slot-scope="scope">
                            <el-button size="mini" @click="BuyPackage(scope.row.id, 'ai')">购买</el-button>
                        </template>
                    </el-table-column>
                
                </el-table>
        
        
            </div>
        
        
        
        </el-drawer>


        <el-main style="background: #f4f8fa;">
            <router-view></router-view>
        </el-main>

    </el-container>

</template>

<script>
    import HTTP from '@/request/api'
    export default {
        data() {
            return {
                activeName: 'tc',
                viewLabel: false,
                isCollapse: false,
                logo_url: require("@/assets/images/logo.png"),
                params: {
                    old_pass: '',
                    new_pass: '',
                    change_pass: ''
                },
                charge: false,
                package: [],
                package_photo: [],
                package_record_params: {
                    page: 1,
                    limit: 10
                },
                packageRecord: [],
                ai_charge: false,
                package_ai: [],
            }
        },
        mounted() {
            this.GetVideoPackage()
            this.GetPhotoPackage()
            this.GetPackageVideoRecord()
            this.GetAiPackage()
        },
        methods: {
            ViewMessage: function(e) {
                this.$message.info(e)
            },
            GetVideoPackage: function() {
                HTTP.getPackageVideo().then(res => {
                    this.package = res.data
                })
            },
            GetPhotoPackage: function(){
              HTTP.getPackagePhoto().then(res=>{
                  this.package_photo = res.data
              })  
            },
            GetAiPackage: function(){
                HTTP.getAiPackage().then(res=>{
                    this.package_ai = res.data
                })
            },
            BuyPackage: function(id, method) {
                if(method == 'video'){
                    HTTP.buyPackageVideo({
                        id: id
                    }).then(res => {
                        this.$message.success(res.message)
                    })
                }else if(method == 'ai'){
                    HTTP.BuyAiPackage({
                        id: id
                    }).then(res=>{
                        this.$message.success(res.message)
                    })
                }
                else{
                    HTTP.buyPackagePhoto({
                        id: id
                    }).then(res => {
                        this.$message.success(res.message)
                    })
                }
            },
            
            GetPackageVideoRecord: function() {
                HTTP.getPackageVideoRecord(this.package_record_params).then(res => {
                    this.packageRecord = res
                })
            },

            setCollapse: function(val) {
                this.isCollapse = !this.isCollapse
            },
            jump: function(name) {
                this.$router.push({
                    name: name
                })
            },
            submit: function() {
                HTTP.revisePassword(this.params).then(res => {
                    this.$message.success(res.message)
                    this.viewLabel = false
                })
            },
            handleCommand(command) {
                if (command == 'pass') {
                    this.viewLabel = true
                } else {
                    sessionStorage.clear()
                    this.$message.success("退出成功!")
                    this.$router.push({
                        name: 'Login'
                    })
                }
            },
            
            handleSizeChange(value) {
                this.package_record_params.limit = value
                this.GetPackageVideoRecord()
            },
            handleCurrentChange(value) {
                this.package_record_params.page = value
                this.GetPackageVideoRecord()
            },
        }
    }
</script>

<style scoped>
    .body {
        height: 100%;
        min-width: 1400px;
    }

    /* 545c64 */
    /* 09f */
    .el-header {
        background: #545c64;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>
