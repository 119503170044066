<template>

    <el-row style="min-width: 1200px;" v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="服务努力加载中"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">

        <el-card class="box-card">
            <div slot="header" class="clearfix">

                <div style="display: flex; justify-content: space-between;">
                    <span>任务中心</span>
                    <span>视频翻译剩余额度：{{ userinfo }} 次</span>
                </div>



            </div>

            <div>

                <el-alert :closable="false" style="margin-bottom: 10px;" title="视频字幕翻译 目前支持抖音/本地视频 请输入3分钟以内的视频, 超过时长可能会出现异常状态. 每次扣除一积分"
                    type="info" show-icon>
                </el-alert>

                <div>
                    <el-input v-model="video_params.url" clearable style="width: 250px; margin-right: 10px;" size="small"
                        placeholder="搜索视频记录地址" prefix-icon="el-icon-search"></el-input>
                    <el-button size="small" icon="el-icon-search" @click="GetUserVideoTrans">搜索一下</el-button>
                    <el-button size="small" type="primary" icon="el-icon-circle-plus-outline" @click="dialogFormVisible=true">创建视频翻译</el-button>
                </div>




                <el-dialog custom-class="dialog-box-card" title="创建视频" :visible.sync="dialogFormVisible" append-to-body>

                    <el-form label-width="80px" label-position="left">

                        <el-tabs v-model="activeName">
                            <el-tab-pane label="视频地址" name="first">
                                <el-input v-model="params.url" type="textarea" :rows="2" placeholder="输入短视频地址或分享地址, 目前支持抖音\mp4视频链接">
                                </el-input>
                            </el-tab-pane>
                            <el-tab-pane label="本地视频" name="second">
                                <el-upload class="123" :limit='1' name="file-stream" accept=".mp4" :before-upload="uploadSuccess"
                                    :on-remove="handleRemove" :on-exceed="uploadExceed" :on-success="uploadVideoSuccess"
                                    :on-error="uploadVideoError" drag action="https://api2.tosoiot.com/upload" multiple>
                                    <i class="el-icon-upload"></i>
                                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                    <div class="el-upload__tip" slot="tip">只能上传视频文件，视频时长5分钟以内，且不超过200MB大小</div>
                                </el-upload>
                            </el-tab-pane>
                        </el-tabs>





                        <el-form-item label="源语言:" style="margin-top: 25px;">
                            <el-select v-model="params.sourceLanguage" placeholder="请选择">
                                <el-option v-for="item in sourceLanguage" :key="item.value" :label="item.label" :value="item.value">
                                    <span style="float: left">{{ item.label }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="目标语言:">
                            <el-select v-model="params.targetLanguage" placeholder="请选择">
                                <el-option v-for="item in targetLanguage" :key="item.value" :label="item.label" :value="item.value">
                                    <span style="float: left">{{ item.label }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="">
                            <el-button clearable icon="el-icon-thumb" size="small" type="infor" @click="CreateVideoTrans">创建任务</el-button>
                        </el-form-item>

                    </el-form>

                </el-dialog>


                <div>
                    <el-table :data="data.data" style="height: 100%;min-height: 600px;">
                        <template slot="empty">
                            <el-empty :image-size="200" description="暂未查询到发布的任务数据哦!"></el-empty>
                        </template>
                        <el-table-column type="index"></el-table-column>

                        <el-table-column prop="coverUrl" label="视频主图" align="center">
                            <template slot-scope="scope">
                                <el-image style="width: 100px; height: 100px" :src="scope.row.coverUrl" fit="fill"></el-image>
                            </template>
                        </el-table-column>

                        <el-table-column prop="url" label="视频信息" align="center" width="450">
                            <template slot-scope="scope">
                                <h4>{{scope.row.title}}</h4>
                                <el-link :href="scope.row.url" target="_blank">{{scope.row.url}}</el-link>
                            </template>
                        </el-table-column>

                        <el-table-column prop="sourceLanguage" label="源语言" align="center">
                            <template slot-scope="scope">
                                <span>{{Language[scope.row.sourceLanguage]}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="targetLanguage" label="目标语言" align="center">
                            <template slot-scope="scope">
                                <span>{{Language[scope.row.targetLanguage]}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="originVideoLength" label="视频时长" align="center">

                        </el-table-column>

                        <el-table-column prop="videoStatus_type" label="视频状态" align="center">
                            <template slot-scope="scope">
                                <div style="display: block;">
                                    <el-tag size="mini" type="danger" v-if="scope.row.videoStatus == -1 | scope.row.videoStatus == 2">{{ scope.row.videoStatus_type }}</el-tag>
                                    <el-tag size="mini" v-else-if="scope.row.videoStatus == 0" type="warning">{{ scope.row.videoStatus_type }}</el-tag>
                                    <el-tag size="mini" v-else type="success">{{ scope.row.videoStatus_type }}</el-tag>
                                    <el-button icon="el-icon-loading" style="display: block; margin: 0 auto;" type="text"
                                        size="mini" @click="GetUserVideoRefresh(scope.row.id)">刷新状态</el-button>
                                </div>

                            </template>
                        </el-table-column>

                        <el-table-column prop="create_time" label="创建时间" width="150" align="center">
                        </el-table-column>

                        <el-table-column label="操作" width="200" align="center">
                            <template slot-scope="scope">
                                <el-button type="infor" size="mini" @click="GetUserVideoEdit(scope.row.id)">编辑</el-button>
                                <el-button icon="el-icon-upload" type="infor" size="mini" @click="GetUserVideoDown(scope.row.id)">下载视频</el-button>
                            </template>
                        </el-table-column>

                    </el-table>

                    <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="this.video_params.page" :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next"
                        :total="data.total" style="margin-top:15px; margin-bottom: 15px; position: relative;">
                    </el-pagination>
                </div>


                <!-- 弹出视频编辑 -->
                <el-drawer title="视频编辑" :visible.sync="edit" size="90%">
                    <div style=" height: 100%; background-color: #2C3E50;">
                        <iframe v-if="edit" id="editor" title="象寄视频编辑器" style="width: 100%; height: 100%;" :src="edit_url"
                            frameborder="0" onload="iframeOnload()" security="restricted"></iframe>
                    </div>
                </el-drawer>


            </div>
        </el-card>


    </el-row>

</template>

<script>
    import HTTP from '@/request/api'

    export default {
        data() {
            return {
                activeName: 'first',
                dialogFormVisible: false,
                fullscreenLoading: false,
                edit: false,
                edit_url: '',
                userinfo: [],
                sourceLanguage: [{
                    value: 'CHS',
                    label: '简体中文'
                }, {
                    value: 'CHT',
                    label: '中文(繁體)'
                }, {
                    value: 'ENG',
                    label: '英语'
                }, {
                    value: 'JPN',
                    label: '日本语'
                }],
                targetLanguage: [
                    {value: 'AR',label: '阿拉伯语'},
                    {value: 'CSY',label: '捷克语'},
                    {value: 'DEU',label: '德语'},
                    {value: 'ENG',label: '英语'},
                    {value: 'ESP',label: '西班牙语'},
                    {value: 'TL',label: '他加禄语(菲律宾)'},
                    {value: 'FRA',label: '法语'},
                    {value: 'HUN',label: '匈牙利语'},
                    {value: 'ID',label: '印尼语'},
                    {value: 'ITA',label: '意大利语'},
                    {value: 'JPN',label: '日本语'},
                    {value: 'JW',label: '爪哇语'},
                    {value: 'KOR',label: '韩国语'},
                    {value: 'MS',label: '马来语'},
                    {value: 'MY',label: '缅甸语'},
                    {value: 'NLD',label: '荷兰语'},
                    {value: 'PLK',label: '波兰语'},
                    {value: 'PTB',label: '葡萄牙语(巴西)'},
                    {value: 'ROM',label: '罗马尼亚语'},
                    {value: 'RUS',label: '俄语'},
                    {value: 'TH',label: '泰语'},
                    {value: 'TRK',label: '土耳其语'},
                    {value: 'VIN',label: '越南语'},
                    {value: 'CHS',label: '中文(简体)'},
                    {value: 'CHT',label: '中文(繁體)'}
                ],
                params: {
                    url: '',
                    sourceLanguage: '',
                    targetLanguage: ''
                },
                video_params: {
                    url: '',
                    page: 1,
                    limit: 10
                },
                data: [],

                Language: {
                    AR: "阿拉伯语",
                    CSY: "捷克语",
                    DEU: "德语",
                    ENG: '英语',
                    ESP: '西班牙语',
                    TL: '他加禄语(菲律宾)',
                    FRA: '法语',
                    HUN: '匈牙利语',
                    ID: '印尼语',
                    ITA: '意大利语',
                    JPN: '日本语',
                    JW: '爪哇语',
                    KOR: '韩国语',
                    MS: '马来语',
                    MY: '缅甸语',
                    NLD: '荷兰语',
                    PLK: '波兰语',
                    PTB: '葡萄牙语(巴西)',
                    ROM: '罗马尼亚语',
                    RUS: '俄语',
                    TH: '泰语',
                    TRK: '土耳其语',
                    VIN: '越南语',
                    CHS: '中文(简体)',
                    CHT: '中文(繁體)',
                }
            }
        },
        created() {
            this.$nextTick(() => {
                // 禁用右键
                document.oncontextmenu = new Function('event.returnValue=false')
                // 禁用选择
                document.onselectstart = new Function('event.returnValue=false')
                //禁止f12
                document.οnkeydοwn = new Function('event.returnValue=false')
            })


        },
        mounted() {
            this.GetVideoTransCount()
            this.GetUserVideoTrans()
        },
        methods: {
            // 视频上传成功
            uploadVideoSuccess: function(response, file, fileList) {
                if (response.Code == 200) {
                    this.params.url = response.Data.Url
                    this.$message.success("视频上传成功")
                }
            },
            // 上传失败
            uploadVideoError(e) {
                this.$message.error('上传失败', 'error')
            },
            // 个数超出
            uploadExceed: function(files, fileList) {
                if (fileList.length == 1) {
                    this.$message.error("最多上传一条视频，请清除上一条视频后再上传。")
                }
            },

            // 上传前调用
            uploadSuccess: function(file) {
                // 截取上传文件的后缀名
                let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
                // 判断文件名的类型
                if (fileType === 'mp4') {
                    return true
                } else {
                    this.$message.error('上传文件仅支持mp4格式');
                    return false
                }

            },

            handleRemove: function(file, fileList) {
                this.params.url = ''
            },

            // 获取video翻译可用次数
            GetVideoTransCount: function() {
                HTTP.getVideoCount().then(res => {
                    this.userinfo = res.data
                })
            },
            // 创建视频翻译
            CreateVideoTrans: function() {
                this.fullscreenLoading = true
                HTTP.createVideoTrans(this.params).then(res => {
                    // 成功取消遮挡
                    this.fullscreenLoading = false
                    this.$message.success(res.message)
                    this.GetUserVideoTrans()
                    this.dialogFormVisible = false
                    
                    // 定时获取新任务状态
                    this.countDown = setInterval(() => {
                        HTTP.userVideoRefresh({
                            id: res.data
                        }).then(res => {
                            if(res.data != '-1'){
                                clearInterval(this.countDown);
                                this.$message.success("AI翻译处理完成，可以点击编辑精修啦~")
                                this.GetUserVideoTrans()
                            }
                        })
                    }, 10000);
                    
                }).finally(err => {
                    this.fullscreenLoading = false
                })
            },
            // 获取视频翻译记录
            GetUserVideoTrans: function() {
                HTTP.userVideoList(this.video_params).then(res => {
                    this.data = res
                })
            },
            // 获取视频下载
            GetUserVideoDown: function(id) {
                HTTP.userVideoDown({
                    id: id
                }).then(res => {
                    this.GetUserVideoTrans()
                    window.open(res.data, '_blank')
                })
            },
            // 获取视频编辑
            GetUserVideoEdit: function(id) {
                HTTP.userVideoEdit({
                    id: id
                }).then(res => {
                    this.GetUserVideoTrans()
                    this.edit = true
                    this.edit_url = res.data
                })
            },
            // 刷新视频状态
            GetUserVideoRefresh: function(id) {
                HTTP.userVideoRefresh({
                    id: id
                }).then(res => {
                    this.GetUserVideoTrans()
                    this.$message.success(res.message)
                })
            },

            handleSizeChange(value) {
                this.video_params.limit = value
                this.GetUserVideoTrans()
            },
            handleCurrentChange(value) {
                this.video_params.page = value
                this.GetUserVideoTrans()
            },

        }
    }
</script>

<style scoped>
    .el-row {
        text-align: left;
    }

    .user-box {
        background: #fff;
        height: 100px;
        padding: 20px;
        margin-bottom: 20px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    }

    .el-row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .box-card {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: #000000d9;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum";
        position: relative;
        background: #fff;
        border-radius: 10px;
    }


    /deep/.dialog-box-card {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: #000000d9;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum";
        position: relative;
        background: #fff;
        border-radius: 10px;
        min-width: 520px;
        max-width: 520px;
        margin: 0 auto;
    }

    /deep/ .el-dialog__header {
        padding: 16px 24px;
        color: #000000d9;
        border-bottom: 1px solid #f0f0f0;
    }

    /* .el-upload--picture-card {
        width: 400px !important;
        height: 300px;
        line-height: 300px;
        background-color: pink;
    } */

    /deep/ .el-upload-dragger {
        background-color: #fff;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        box-sizing: border-box;
        width: 480px;
        min-width: 480px;
        height: 150px;
        text-align: center;
        position: relative;
        overflow: hidden;
    }
</style>
