<template>



	<el-row style="min-width: 1200px;">

		<meta name="referrer" content="never">

		<el-row :gutter="15">
			<el-col :span="12">
				<el-card class="box-card" style="height: 140px; position: relative;">
					<div style="width: 110px; text-align: center; position: relative; float: left;">
						<!-- <el-avatar style="width: 100px; height: 100px;" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar> -->
						<el-avatar style="width: 100px; height: 100px; background-color: #eee; border: 1px solid #eee;" :src="tx_url"></el-avatar>
					</div>
					<div style="width: 250px; float: left; font-size: 14px; font-weight: 1000;">
						<div style="padding: 20px;">
							<span>欢迎您！{{ userinfo.username }}</span>
						</div>
						<div style="padding: 10px 0px 20px 20px;">
							<span>欢迎登录云象视频字幕翻译系统</span>
						</div>
					</div>
					<div style="width: 230px; height: 100px; float: right; font-size: 14px; font-weight: 1000; border: #eee 1px solid; border-radius: 13px; margin-right: 15px;">
						<div style="padding: 20px 20px 10px 20px">
							<i class="el-icon-wallet" style="color: #42B983;margin-right: 5px;"></i>
							<span>钱包余额</span>
						</div>
						<div style="padding: 10px 0px 20px 20px;">
							<span style="font-size: 20px; float: left; padding: 0 10px;">{{ userinfo.balance }}</span>
							<el-button size="mini" style="float: right; margin-right: 15px;" type="primary" icon="el-icon-wallet" @click="$router.push({name: 'Pay'})">充值</el-button>
						</div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="height: 140px; position: relative;">

					<el-col :span="6">
						<div>

							<div style="padding: 20px; min-width: 150px;">
								<i class="el-icon-video-camera-solid" style="font-size: 60px; color: #99A9BF;"></i>
								<!-- F4A460 -->
								<div style="width: 90px; height: 60px; float: right; font-weight: 600;">
									<span style="font-size: 18px; float: left; width: 90px; padding: 5px 0px 10px 10px;">{{ userinfo.remaining_num }}</span>
									<span style="font-size: 14px; float: left; width: auto; padding: 0px 0px 10px 10px;">视频额度</span>
								</div>

							</div>

						</div>
					</el-col>
                    
                    <el-col :span="6">
                    	<div>
                    
                    		<div style="padding: 20px; min-width: 150px;">
                    			<i class="el-icon-picture-outline" style="font-size: 60px; color: #99A9BF;"></i>
                    			<!-- F4A460 -->
                    			<div style="width: 90px; height: 60px; float: right; font-weight: 600;">
                    				<span style="font-size: 18px; float: left; width: 90px; padding: 5px 0px 10px 10px;">{{ userinfo.photo_remaining_num }}</span>
                    				<span style="font-size: 14px; float: left; width: auto; padding: 0px 0px 10px 10px;">图片额度</span>
                    			</div>
                    
                    		</div>
                    
                    	</div>
                    </el-col>


					<el-col :span="6">
						<div>
							<div style="padding: 20px; min-width: 150px;">
								<!-- 409EFF -->
								<i class="el-icon-mobile-phone" style="font-size: 60px; color: #99A9BF;"></i>
								<div style="width: 90px; height: 60px; float: right; font-weight: 600;">
									<span style="font-size: 18px; float: left; width: 90px; padding: 5px 0px 10px 10px;">{{ userinfo.login_count }}</span>
									<span style="font-size: 14px; float: left; width: auto; padding: 0px 0px 10px 10px;">登录次数</span>
								</div>
							</div>

						</div>
					</el-col>

					<el-col :span="6">
						<div>

							<div style="padding: 20px; min-width: 150px;">
								<i class="el-icon-s-marketing" style="font-size: 60px; color: #99A9BF;"></i>
								<div style="width: 90px; height: 60px; float: right; font-weight: 600;">
									<span style="font-size: 18px; float: left; width: 90px; padding: 5px 0px 10px 10px;">{{ userinfo.video_trans_count + userinfo.photo_trans_count }}</span>
									<span style="font-size: 14px; float: left; width: auto; padding: 0px 0px 10px 10px;">翻译次数</span>
								</div>
							</div>

						</div>
					</el-col>


				</el-card>
			</el-col>
		</el-row>

		<el-row :gutter="15">
			<el-col :span="24">
				<el-row :gutter="15">
					<el-col :span="8">
						<el-card class="box-card" style="height: auto;">
							<div>
								<div id="main2" style="height:600px;margin-top:10px;"></div>
							</div>
						</el-card>
					</el-col>

					<el-col :span="10">
						<el-card class="box-card" style="height:650px;">

							<el-alert :closable="false" style="margin-bottom: 10px;" title="输入抖音分享链接,在线播放视频,快来试试哦~ 链接格式: https://v.douyin.com/rhEgNb7/"
							 type="info" show-icon>
							</el-alert>

							<div class="search" style="margin: 20px auto 0;">
								<div class="select-wrap">
									<img :src="dy_url" class="logo">
									<span class="text" slot="reference">抖音</span>
								</div>

								<div class="search-input-wrap">
									<input v-model="dy_params" type="text" class="search-input" placeholder="输入抖音分享链接,查看视频信息">
								</div>

								<div class="search-btn" @click="GetDouyinInfo">
									查看
								</div>

							</div>

							<div v-if="dy_data" style="height: 400px; display: flex; justify-content: space-between; padding: 20px 0px;">

								<div class="box-card" style="height:450px; width: 300px; position: relative; padding: 0; box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);">
									<div class="box-card" style="width: 100%; background: #000; height: 300px;  padding: 0; border-radius: 10px 10px 0 0;">
										<video :autoplay="true" :src="dy_data.play_url" style="height: 100%; width: 100%;"></video>
									</div>

									<div style="height: 80px; overflow: hidden; padding: 10px;border-top: 1px solid rgba(0,0,0,.1); border-bottom: 1px solid rgba(0,0,0,.1);">
										<span style="font-size: 12px;">{{dy_data.title}}</span>
									</div>

									<div style="text-align: center;padding: 8px;">
										<el-button type="infor" size="small" @click="copy">复制文案</el-button>
										<el-button type="infor" size="small" @click="getdown">提取视频</el-button>
									</div>
								</div>

								<div style="width: 400px; margin: auto auto;">

									<div style="text-align: center; position: relative; margin: 0 auto; align-items: center;">
										<el-avatar src="https://empty" @error="errorHandler" style="width: 200px; height: 200px; background-color: #eee; border: 1px solid #eee;" :src="dy_data.image">
											<img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/>
										</el-avatar>
									</div>


									<div style="text-align: center; margin: 0 auto; width: 100%; padding: 20px 0px;">
										<span>作者：{{ dy_data.nickname || '输入抖音链接查看' }}</span>
									</div>
									
									<div style="text-align: center; width: 100%; margin: 30px auto 0px;">
										<el-badge style="margin-right: 40px;" :value="dy_data.statistics.comment_count" class="item">
											<el-button icon="el-icon-chat-square" size="small">评论</el-button>
										</el-badge>
										<el-badge style="margin-right: 40px;" :value="dy_data.statistics.digg_count" class="item">
											<el-button icon="el-icon-magic-stick" size="small">点赞</el-button>
										</el-badge>
										<el-badge :value="dy_data.statistics.share_count" class="item">
											<el-button icon="el-icon-star-off" size="small">收藏</el-button>
										</el-badge>
									</div>
								</div>

							</div>

						</el-card>
					</el-col>

					<el-col :span="6">
						<el-card class="box-card" style="height: 285px; margin-bottom: 15px;">
							<div class="carousel-warp">
								<el-carousel height="250px" width="100%" direction="horizontal" :autoplay="true" :interval="5000">
									
									<el-carousel-item v-for="item in carousel">
										<a :href="item.url" target="_blank">
											<el-image style='width: 100%; height: 100%;' :src="item.image"></el-image>
										</a>
									</el-carousel-item>
									
									
									
									<!-- <el-carousel-item>
										<el-image style='width: 100%; height: 100%;' src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Finews.gtimg.com%2Fnewsapp_bt%2F0%2F13224601305%2F641&refer=http%3A%2F%2Finews.gtimg.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1671088479&t=9a37a5bacf5e52898b2130b8a9a12c93"></el-image>
									</el-carousel-item>
									
									<el-carousel-item>
										<el-image style='width: 100%; height: 100%;' src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fbkimg.cdn.bcebos.com%2Fpic%2F95eef01f3a292df57f9d704cb3315c6034a87309&refer=http%3A%2F%2Fbkimg.cdn.bcebos.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1671088594&t=fd7eaeecea902914ad4c2db4bb51b70a"></el-image>
									</el-carousel-item>
									
									<el-carousel-item>
										<el-image style='width: 100%; height: 100%;' src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg49.gkzhan.com%2F9%2F20181026%2F636761667701074590731.jpg&refer=http%3A%2F%2Fimg49.gkzhan.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1671088594&t=b31eb7028fe6e394b070038ff04a5d62"></el-image>
									</el-carousel-item> -->
									
									
									
								</el-carousel>
							</div>
						</el-card>
						<el-card class="box-card" style="height: 350px;">
							<div>
								<el-table :data="notice.data">
									<template slot="empty">
										<el-empty :image-size="150" description="暂无公告信息"></el-empty>
									</template>
									<el-table-column prop="create_time" label="日期" align="center" width="100"></el-table-column>
									<el-table-column prop="title" label="公告" align="center">
										<template slot-scope="scope">
											<el-link @click="ViewNotice(scope.row.id)">{{scope.row.title}}</el-link>
											<!-- <span style="cursor: pointer;" @click="ViewNotice(scope.row.id)">{{scope.row.title}}</span> -->
										</template>
									</el-table-column>
									
								
									<!-- <el-table-column label="操作" width="200" align="center">
								
										<template slot="header" slot-scope="scope">
											<el-button type="primary" icon="el-icon-refresh" size="mini" @click="GetVideoPackage">刷新</el-button>
										</template>
								
										<template slot-scope="scope">
											<el-button size="mini" @click="BuyVideoPackage(scope.row.id)">购买</el-button>
										</template>
									</el-table-column> -->
								
								</el-table>
							</div>
						</el-card>
					</el-col>

				</el-row>
				
				
				<el-dialog custom-class="dialog-box-card" :title="notice_data.title" :visible.sync="dialogFormVisible" append-to-body style="min-width: 910px; max-width: 910px; margin: 0 auto;">
					
					<div v-html="notice_data.content">
						<!-- {{ notice_data.content }} -->
					</div>
					
				
				</el-dialog>
				

			</el-col>
		</el-row>
	</el-row>

</template>

<script>
	import HTTP from '@/request/api'

	export default {
		data() {
			return {
				tx_url: require("@/assets/images/yunxiang.png"),
				dy_url: require("@/assets/images/dy.png"),
				play_url: 'https://aweme.snssdk.com/aweme/v1/play/?video_id=v0d00fg10000cdp03ujc77u1a3qpcm30&ratio=720p&line=0',
				params: {
					page: 1,
					limit: 10
				},
				result: [],
				userinfo: '',
				dy_params: '',
				dy_data: '',
				notice_params: {
					page: 1,
					limit: 5
				},
				notice: [],
				dialogFormVisible: false,
				notice_data: "",
				carousel_params: {
					page: 1,
					limit: 10
				},
				carousel: ""
			}
		},
		created() {

		},
		mounted() {
			this.GetUserInfo()
			this.GetNoticeList()
			this.GetCarousel()
		},
		methods: {
			// 轮播图
			GetCarousel: function(){
				HTTP.carousel(this.carousel_params).then(res=>{
					this.carousel = res.data
				})
				
			},
			// 查看公告
			ViewNotice: function(id){
				HTTP.getNoticeContent({id: id}).then(res=>{
					this.notice_data = res.data
					this.dialogFormVisible = true
				})
				
			},
			// 获取公告内容
			GetNoticeList: function(){
				HTTP.getNoticeList(this.notice_params).then(res=>{
					this.notice = res
				})
			},
			// 复制到剪辑版
			copy: function(){
				navigator.clipboard.writeText(this.dy_data.title).then(res=>{
					this.$message.success('复制成功')
				})
			},
			// 提取视频
			getdown: function(){
				window.open(this.dy_data.play_url, '_blank')
			},
			// 获取抖音
			GetDouyinInfo: function() {
				HTTP.getDouyin({
					url: this.dy_params
				}).then(res => {
					this.$message.success(res.message)
					this.dy_data = res.data
				})
			},
			GetUserInfo: function() {
				// 新
				HTTP.userinfo().then(res => {
					this.userinfo = res.data
					// this.echartsInit()
					this.echartsInit2()
				})
			},
			errorHandler() {
				return true
			},
			echartsInit2: function() {

				var myChart2 = this.$echarts.init(document.getElementById('main2'))
				var option2 = {
					title: {
						text: '使用比例',
						subtext: 'Data',
						left: 'left'
					},
					tooltip: {
						trigger: 'item'
					},
					legend: {
						orient: 'vertical',
						left: 'right'
					},
					series: [{
						name: '比例数量',
						type: 'pie',
						radius: '60%',
						data: this.userinfo.charts_data,
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}]
				}

				myChart2.setOption(option2, true, false)
			},


			echartsInit: function() {
				var myChart = this.$echarts.init(document.getElementById('main'));

				var option = {

					title: {
						text: '您的换绑走势',
					},
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					toolbox: {
						feature: {
							saveAsImage: {}
						}
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: this.userinfo.charts_baidu_data.time
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						name: '换绑条数',
						type: 'line',
						stack: 'Total',
						data: this.userinfo.charts_baidu_data.data,
					}, ]

				}

				myChart.setOption(option, true, false)
			},
		}
	}
</script>

<style scoped>
	.el-row {
		text-align: left;
	}

	.user-box {
		background: #fff;
		height: 100px;
		padding: 20px;
		margin-bottom: 20px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
	}

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.el-col {
		border-radius: 4px;
	}

	.box-card {
		height: 205px;
	}

	.box-card {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		color: #000000d9;
		font-size: 14px;
		font-variant: tabular-nums;
		line-height: 1.5715;
		list-style: none;
		font-feature-settings: "tnum";
		position: relative;
		background: #fff;
		border-radius: 10px;
	}

	.bg-purple-dark {
		background: #99a9bf;
	}

	.bg-purple {
		background: #d3dce6;
		height: 150px;
	}

	.bg-purple-light {
		background: #e5e9f2;
	}

	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}

	.row-bg {
		padding: 10px 0;
		background-color: #f9fafc;
	}

	.el-carousel__item h3 {
		color: #475669;
		font-size: 14px;
		opacity: 0.75;
		line-height: 250px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #d3dce6;
	}


	.search {
		position: relative;
		text-align: center;
		width: 100%;
		display: flex;
		align-items: center;
		margin: 0 auto;
		margin: 70px auto 0;
		border-radius: 5px;
		/* background: #eee; */
		background-color: rgb(244, 248, 250);
		;
		cursor: pointer;
	}

	.search .select-wrap {
		position: relative;
		display: flex;
		align-items: center;
		color: #333;
		font-size: 18px;
		padding: 15px 20px;
	}

	.search .select-wrap .logo {
		width: 24px;
		padding-right: 10px;
		align-items: center;
	}

	.search .search-input-wrap {
		position: relative;
		flex: 1;

	}

	.search .search-input-wrap .search-input {
		border: none;
		color: #333;
		outline: none;
		padding: 15px;
		font-size: 18px;
		width: 100%;
		box-sizing: border-box;
	}

	.search .search-btn {
		display: flex;
		align-items: center;
		background: #CCC;
		/* background: #E6A23C; */
		/* background: rgb(244, 248, 250);; */
		text-align: center;
		color: #fff;
		border: none;
		height: 56.6px;
		padding: 0 40px;
		font-size: 18px;
		cursor: pointer;
		border-radius: 0 5px 5px 0;
	}
	
	/deep/.dialog-box-card {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		color: #000000d9;
		font-size: 14px;
		font-variant: tabular-nums;
		line-height: 1.5715;
		list-style: none;
		font-feature-settings: "tnum";
		position: relative;
		background: #fff;
		border-radius: 10px;
		min-width: 910px;
		max-width: 910px;
		margin: 0 auto;
		height: auto;
	}
	
	/deep/ .el-dialog__header {
		padding: 16px 24px;
		color: #000000d9;
		border-bottom: 1px solid #f0f0f0;
	}
	
</style>
