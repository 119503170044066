<template>
  <div>
    <div style="width: 60px; height: auto; display: flex; right: 0; position: absolute; top: 70%; bottom: 30%; border-radius: 5%; margin-right: 20px;">

      <ul style="list-style: none;padding: 0;">
        <li @click="qq = true" style="width: 60px; max-height: 60px; background-color: #fff;margin-bottom: 15px; text-align: center; border: 1px solid #dedede;cursor: pointer;">
          <div>
            <i style="display: block;width:100%; font-size: 32px; margin-right: 0px; color: red;" class="iconfont icon-qq"></i>
          </div>
          <div>
            <span style="font-size: 12px;">QQ咨询</span>
          </div>
        </li>


        <li @click="qq = true" style="width: 60px; max-height: 60px; background-color: #fff;margin-bottom: 15px; text-align: center; border: 1px solid #dedede;cursor: pointer;">
          <div>
            <i style="display: block;width:100%; font-size: 32px; margin-right: 0px; color: limegreen;" class="iconfont icon-lianxi"></i>
          </div>
          <div>
            <span style="font-size: 12px;">微信咨询</span>
          </div>
        </li>

      </ul>


      <el-drawer title="客服列表" :visible.sync="qq" :direction="direction">

        <div v-if="customer !='' " style="padding: 20px;">

          <el-alert title="温馨提示" type="success" description="下方可选择您需要的联系的客服专员, 如咨询未能及时回复, 可咨询另外一个客服! 客服在线时间:周一至周五 上午 09:00 至 下午 18:30 !"
            show-icon>
          </el-alert>

          <el-descriptions :column="2" title="微信客服" border style="margin-top: 15px;">
            <el-descriptions-item v-for="(item, i) in customer">
              <template slot="label">
                <i class="el-icon-user"></i>
                {{ item.name }}
              </template>

              <img style="height: 100px;" border="0" :src="item.v_code" alt="点击这里给我发消息" title='点击这里给我发消息' />
              </a>
            </el-descriptions-item>
          </el-descriptions>
		 
        </div>
		
		
		<div v-else style="padding: 20px;">
			<el-alert title="温馨提示" type="warning" description="当前系统没有客服在线!"
			  show-icon>
			</el-alert>
		</div>
		

      </el-drawer>

    </div>
  </div>
</template>

<script>
  import HTTP from '@/request/api'
  export default {
    data() {
      return {
        qq: false,
        wx: false,
        direction: 'rtl',
        customer: ''
      }
    },
    mounted() {
      this.GetCustomerService()
    },
    methods: {

      GetCustomerService: function() {
        HTTP.kefu().then(res => {
          this.customer = res.data
        })
      }
    }
  }
</script>

<style>

</style>
