<template>

	<el-row style="min-width: 1200px;">

		<el-card class="box-card">
			<div slot="header" class="clearfix">

				<div style="display: flex; justify-content: space-between;">
					<span>用户视频翻译管理中心</span>
				</div>
			</div>

			<div>

				<el-alert :closable="false" style="margin-bottom: 10px;" title="用户视频翻译列表管理"
				 type="info" show-icon>
				</el-alert>

				<div>
					<el-input v-model="video_params.username" clearable style="width: 250px; margin-right: 10px;" size="small" placeholder="搜索用户名" prefix-icon="el-icon-search"></el-input>
					<el-input v-model="video_params.url" clearable style="width: 250px; margin-right: 10px;" size="small" placeholder="搜索视频记录地址" prefix-icon="el-icon-search"></el-input>
					<el-button size="small" icon="el-icon-search" @click="GetUserVideoTrans">搜索一下</el-button>
					<!-- <el-button size="small" type="primary" icon="el-icon-circle-plus-outline" @click="dialogFormVisible=true">创建视频翻译</el-button> -->
				</div>


				<!-- <el-dialog custom-class="dialog-box-card" title="创建视频" :visible.sync="dialogFormVisible" append-to-body>

					<el-form label-width="80px" label-position="left">

						<el-input v-model="params.url" type="textarea" :rows="2" placeholder="输入短视频地址或分享地址, 目前支持抖音">
						</el-input>

						<el-form-item label="源语言:" style="margin-top: 25px;">
							<el-select v-model="params.sourceLanguage" placeholder="请选择">
								<el-option v-for="item in sourceLanguage" :key="item.value" :label="item.label" :value="item.value">
									<span style="float: left">{{ item.label }}</span>
									<span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="目标语言:">
							<el-select v-model="params.targetLanguage" placeholder="请选择">
								<el-option v-for="item in targetLanguage" :key="item.value" :label="item.label" :value="item.value">
									<span style="float: left">{{ item.label }}</span>
									<span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="">
							<el-button clearable icon="el-icon-thumb" size="small" type="infor" @click="CreateVideoTrans">创建任务</el-button>
						</el-form-item>

					</el-form>

				</el-dialog> -->


				<div>
					<el-table :data="data.data" style="height: 100%;min-height: 600px;">
						<template slot="empty">
							<el-empty :image-size="200" description="暂未查询到发布的任务数据哦!"></el-empty>
						</template>
						<el-table-column type="index"></el-table-column>
						
						<el-table-column prop="user_id.username" label="所属用户" align="center">
							
						</el-table-column>

						<el-table-column prop="coverUrl" label="视频主图" align="center">
							<template slot-scope="scope">
								<el-image style="width: 100px; height: 100px" :src="scope.row.coverUrl" fit="fill"></el-image>
							</template>
						</el-table-column>

						<el-table-column prop="url" label="视频信息" align="center" width="450">
							<template slot-scope="scope">
								<h4>{{scope.row.title}}</h4>
								<el-link :href="scope.row.url" target="_blank">{{scope.row.url}}</el-link>
							</template>
						</el-table-column>

						<el-table-column prop="sourceLanguage" label="源语言" align="center">
							<template slot-scope="scope">
								<span>{{Language[scope.row.sourceLanguage]}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="targetLanguage" label="目标语言" align="center">
							<template slot-scope="scope">
								<span>{{Language[scope.row.targetLanguage]}}</span>
							</template>
						</el-table-column>

						<el-table-column prop="originVideoLength" label="视频时长" align="center">
							
						</el-table-column>

						<el-table-column prop="videoStatus_type" label="视频状态" align="center">
							<template slot-scope="scope">
								<div style="display: block;">
									<el-tag size="mini" type="danger" v-if="scope.row.videoStatus == -1 | scope.row.videoStatus == 2">{{ scope.row.videoStatus_type }}</el-tag>
									<el-tag size="mini" v-else-if="scope.row.videoStatus == 0" type="warning">{{ scope.row.videoStatus_type }}</el-tag>
									<el-tag size="mini" v-else type="success">{{ scope.row.videoStatus_type }}</el-tag>
									<el-button icon="el-icon-loading" style="display: block; margin: 0 auto;" type="text" size="mini" @click="GetUserVideoRefresh(scope.row.id)">刷新状态</el-button>
								</div>
								
							</template>
						</el-table-column>

						<el-table-column prop="create_time" label="创建时间" width="150" align="center">
						</el-table-column>

						<el-table-column label="操作" width="200" align="center">
							<template slot-scope="scope">
								<el-button type="infor" size="mini" @click="GetUserVideoEdit(scope.row.id)">编辑</el-button>
								<el-button icon="el-icon-upload" type="infor" size="mini" @click="GetUserVideoDown(scope.row.id)">下载视频</el-button>
							</template>
						</el-table-column>

					</el-table>
					
					<el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="this.video_params.page"
					 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next" :total="data.total" style="margin-top:15px; margin-bottom: 15px; position: relative;">
					</el-pagination>
				</div>


				<!-- 弹出视频编辑 -->
				<el-drawer title="视频编辑" :visible.sync="edit" size="90%">
					<div style=" height: 100%; background-color: #2C3E50;">
						<iframe v-if="edit" id="editor" title="象寄视频编辑器" style="width: 100%; height: 100%;" :src="edit_url" frameborder="0" onload="iframeOnload()" security="restricted"></iframe>
					</div>
				</el-drawer>


			</div>
		</el-card>


	</el-row>

</template>

<script>
	import HTTP from '@/request/admin/api'

	export default {
		data() {
			return {
				dialogFormVisible: false,
				edit: false,
				edit_url: '',
				userinfo: [],
				sourceLanguage: [{
					value: 'CHS',
					label: '简体中文'
				}, {
					value: 'CHT',
					label: '中文(繁體)'
				}, {
					value: 'ENG',
					label: '英语'
				}, {
					value: 'JPN',
					label: '日本语'
				}],
				targetLanguage: [{
					value: 'KOR',
					label: '韩国语'
				}, {
					value: 'JPN',
					label: '日本语'
				}, {
					value: 'RUS',
					label: '俄语'
				}, {
					value: 'ENG',
					label: '英语'
				}, {
					value: 'FRA',
					label: '法语'
				}, {
					value: 'DEU',
					label: '德语'
				}, {
					value: 'HUN',
					label: '匈牙利语'
				}, {
					value: 'ITA',
					label: '意大利语'
				}, {
					value: 'PLK',
					label: '波兰语'
				}, {
					value: 'KOR',
					label: '韩国语'
				}, {
					value: 'CSY',
					label: '捷克语'
				}, {
					value: 'NLD',
					label: '荷兰语'
				}, {
					value: 'ESP',
					label: '葡萄牙语'
				}, {
					value: 'ROM',
					label: '罗马尼亚语'
				}, {
					value: 'ESP',
					label: '西班牙语'
				}, {
					value: 'ROM',
					label: '罗马尼亚语'
				}, {
					value: 'TRK',
					label: '土耳其语'
				}, {
					value: 'VIN',
					label: '越南语'
				}, {
					value: 'CHS',
					label: '中文(简体)'
				}, {
					value: 'CHT',
					label: '中文(繁体)'
				}, {
					value: 'AR',
					label: '阿拉伯语'
				}, {
					value: 'ID',
					label: '印尼语'
				}, {
					value: 'TH',
					label: '泰语'
				}, {
					value: 'MS',
					label: '马来语'
				}, {
					value: 'TL',
					label: '他加禄语(菲律宾)'
				}, {
					value: 'JW',
					label: '爪哇语'
				}, {
					value: 'MY',
					label: '缅甸语'
				}, ],
				params: {
					url: '',
					sourceLanguage: '',
					targetLanguage: ''
				},
				video_params: {
					url: '',
					username: '',
					page: 1,
					limit: 10
				},
				data: [],

				Language: {
					KOR: '韩国语',
					JPN: '日本语',
					RUS: '俄语',
					ENG: '英语',
					FRA: '法语',
					DEU: '德语',
					HUN: '匈牙利语',
					ITA: '意大利语',
					PLK: '波兰语',
					KOR: '韩国语',
					CSY: '捷克语',
					NLD: '荷兰语',
					ESP: '葡萄牙语',
					ROM: '罗马尼亚语',
					ESP: '西班牙语',
					ROM: '罗马尼亚语',
					TRK: '土耳其语',
					VIN: '越南语',
					CHS: '中文(简体)',
					CHT: '中文(繁体)',
					AR: '阿拉伯语',
					ID: '印尼语',
					TH: '泰语',
					MS: '马来语',
					TL: '他加禄语(菲律宾)',
					JW: '爪哇语',
					MY: '缅甸语'
				}
			}
		},
		created() {
		
		},
		mounted() {
			this.GetUserVideoTrans()
		},
		methods: {
			// 创建视频翻译
			CreateVideoTrans: function() {
				HTTP.createVideoTrans(this.params).then(res => {
					this.$message.success(res.message)
					this.GetUserVideoTrans()
					this.dialogFormVisible = false
				})
			},
			// 获取视频翻译记录 OK
			GetUserVideoTrans: function() {
				HTTP.userVideoList(this.video_params).then(res => {
					this.data = res
				})
			},
			// 获取视频下载 OK
			GetUserVideoDown: function(id){
				HTTP.userVideoDown({id: id}).then(res=>{
					this.GetUserVideoTrans()
					window.open(res.data, '_blank')
				})
			},
			// 获取视频编辑
			GetUserVideoEdit: function(id){
				HTTP.userVideoEdit({id:id}).then(res=>{
					this.GetUserVideoTrans()
					this.edit = true
					this.edit_url = res.data
				})
			},
			// 刷新视频状态
			GetUserVideoRefresh: function(id){
				HTTP.userVideoRefresh({id:id}).then(res=>{
					this.GetUserVideoTrans()
					this.$message.success(res.message)
				})
			},
			
			handleSizeChange(value) {
				this.video_params.limit = value
				this.GetUserVideoTrans()
			},
			handleCurrentChange(value) {
				this.video_params.page = value
				this.GetUserVideoTrans()
			},
			
		}
	}
</script>

<style scoped>
	.el-row {
		text-align: left;
	}

	.user-box {
		background: #fff;
		height: 100px;
		padding: 20px;
		margin-bottom: 20px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
	}

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.box-card {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		color: #000000d9;
		font-size: 14px;
		font-variant: tabular-nums;
		line-height: 1.5715;
		list-style: none;
		font-feature-settings: "tnum";
		position: relative;
		background: #fff;
		border-radius: 10px;
	}


	/deep/.dialog-box-card {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		color: #000000d9;
		font-size: 14px;
		font-variant: tabular-nums;
		line-height: 1.5715;
		list-style: none;
		font-feature-settings: "tnum";
		position: relative;
		background: #fff;
		border-radius: 10px;
		min-width: 520px;
		max-width: 520px;
		margin: 0 auto;
	}

	/deep/ .el-dialog__header {
		padding: 16px 24px;
		color: #000000d9;
		border-bottom: 1px solid #f0f0f0;
	}
</style>
