<template>

	<el-row style="min-width: 1200px;">

		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>套餐管理</span>
			</div>

			<div>

				<el-alert :closable="false" style="margin-bottom: 10px;" title="管理所有套餐列表" type="info" show-icon>
				</el-alert>


                <el-tabs v-model="active">
                	
                	<el-tab-pane label="翻译套餐设置" name="first">
                		
                		<div>
                			<el-input v-model="package_params.name" clearable style="width: 250px; margin-right: 10px;" size="small"
                			 placeholder="请输入套餐名搜索" prefix-icon="el-icon-search"></el-input>
                			<el-button size="small" icon="el-icon-search" @click="GetPackage">搜索一下</el-button>
                			<el-button size="small" type="primary" icon="el-icon-circle-plus-outline" @click="dialogFormVisible=true">添加套餐</el-button>
                		</div>
                		
                		<el-table :data="package.data">
                			<template slot="empty">
                				<el-empty :image-size="200" description="暂未查询到发布的套餐数据哦!"></el-empty>
                			</template>
                			<el-table-column type="index"></el-table-column>
                			<el-table-column prop="name" label="套餐" align="center"></el-table-column>
                			<el-table-column prop="package_type_display" label="类型" align="center"></el-table-column>
                			<el-table-column prop="price" label="价格" align="center">
                			</el-table-column>
                			<el-table-column prop="package_num" label="次数" align="center">
                			</el-table-column>
                		
                			<el-table-column prop="desc" label="活动" align="center">
                			</el-table-column>
                		
                			<el-table-column label="操作" width="200" align="center">
                		
                				<template slot="header" slot-scope="scope">
                					<el-button type="primary" icon="el-icon-refresh" size="mini" @click="GetPackage">刷新</el-button>
                				</template>
                		
                				<template slot-scope="scope">
                					<el-button size="mini" @click="EditViewInfo(scope.row)">编辑</el-button>
                				</template>
                			</el-table-column>
                		
                		</el-table>
                		
                		
                		<el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="this.package_params.page"
                		 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next" :total="package.total" style="margin-top:15px; margin-bottom: 15px; position: relative;">
                		</el-pagination>
                        
                	</el-tab-pane>
                	
                	
                	<el-tab-pane label="AI配音套餐设置" name="order">
                		
                		<div>
                			<el-input v-model="ai_package_params.name" clearable style="width: 250px; margin-right: 10px;" size="small"
                			 placeholder="请输入套餐名搜索" prefix-icon="el-icon-search"></el-input>
                			<el-button size="small" icon="el-icon-search" @click="GetAiPackage">搜索一下</el-button>
                			<el-button size="small" type="primary" icon="el-icon-circle-plus-outline" @click="ai_addVisible=true">添加套餐</el-button>
                		</div>
                		
                		<el-table :data="ai_data.data">
                			<template slot="empty">
                				<el-empty :image-size="200" description="暂未查询到发布的套餐数据哦!"></el-empty>
                			</template>
                			<el-table-column type="index"></el-table-column>
                			<el-table-column prop="name" label="套餐" align="center"></el-table-column>
                            <el-table-column prop="time" label="时间" align="center">
                            </el-table-column>
                			<el-table-column prop="time_type_display" label="类型" align="center"></el-table-column>
                			<el-table-column prop="price" label="价格" align="center">
                			</el-table-column>
                			
                		
                			<el-table-column prop="desc" label="活动" align="center">
                			</el-table-column>
                		
                			<el-table-column label="操作" width="200" align="center">
                		
                				<template slot="header" slot-scope="scope">
                					<el-button type="primary" icon="el-icon-refresh" size="mini" @click="GetAiPackage">刷新</el-button>
                				</template>
                		
                				<template slot-scope="scope">
                					<el-button size="mini" @click="EditAIViewInfo(scope.row)">编辑</el-button>
                				</template>
                			</el-table-column>
                		
                		</el-table>
                		
                		
                		<el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="ai_package_params.page"
                		 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next" :total="ai_data.total" style="margin-top:15px; margin-bottom: 15px; position: relative;">
                		</el-pagination>
                        
                	</el-tab-pane>
                </el-tabs>
                
                
                

			

		
				<el-dialog title="添加套餐" :visible.sync="dialogFormVisible" append-to-body style="min-width: 510px; max-width: 910px; margin: 0 auto;">
				
					<el-form label-width="100px" label-position="right" style="min-width: 600px;">
				
						<el-form-item label="套餐名称:">
							<el-input style="width: 218px;" v-model="add_params.name"></el-input>
						</el-form-item>
				
						<el-form-item label="价格:">
							<el-input style="width: 218px;" v-model="add_params.price"></el-input>
						</el-form-item>
				
						<el-form-item label="次数:">
							<el-input style="width: 218px;" v-model="add_params.package_num"></el-input>
						</el-form-item>
				
						<el-form-item label="活动介绍:">
							<el-input style="width: 218px;" v-model="add_params.desc"></el-input>
						</el-form-item>
				
						<el-form-item label="类型:">
							<el-select v-model="add_params.package_type" placeholder="请选择套餐类型">
								<el-option v-for="item in type_options" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
				
						<el-form-item label="">
							<el-button clearable icon="el-icon-thumb" size="small" type="primary" @click="AddPackage">添加套餐</el-button>
						</el-form-item>
				
					</el-form>
				
				</el-dialog>
                
                
                <el-dialog title="添加AI配音套餐" :visible.sync="ai_addVisible" append-to-body style="min-width: 510px; max-width: 910px; margin: 0 auto;">
                
                	<el-form label-width="100px" label-position="right" style="min-width: 600px;">
                
                		<el-form-item label="套餐名称:">
                			<el-input style="width: 218px;" v-model="ai_add_params.name"></el-input>
                		</el-form-item>
                
                		<el-form-item label="价格:">
                			<el-input style="width: 218px;" v-model="ai_add_params.price"></el-input>
                		</el-form-item>

                		<el-form-item label="套餐时间:">
                			<el-input style="width: 218px;" v-model="ai_add_params.time"></el-input>
                		</el-form-item>
                        
                        <el-form-item label="时间类型:">
                        	<el-select v-model="ai_add_params.time_type" placeholder="请选择时间类型">
                        		<el-option v-for="item in time_options" :key="item.value" :label="item.label" :value="item.value">
                        		</el-option>
                        	</el-select>
                        </el-form-item>
                
                		<el-form-item label="活动介绍:">
                			<el-input style="width: 218px;" v-model="ai_add_params.desc"></el-input>
                		</el-form-item>
                
                		<el-form-item label="状态:">
                			<el-select v-model="ai_add_params.state" placeholder="套餐状态">
                				<el-option v-for="item in state_options" :key="item.value" :label="item.label" :value="item.value">
                				</el-option>
                			</el-select>
                		</el-form-item>
                
                		<el-form-item label="">
                			<el-button clearable icon="el-icon-thumb" size="small" type="primary" @click="AddAiPackage">添加套餐</el-button>
                		</el-form-item>
                
                	</el-form>
                
                </el-dialog>



				<el-dialog title="编辑套餐" :visible.sync="editVisible" append-to-body style="min-width: 510px; max-width: 910px; margin: 0 auto;">

					<el-form label-width="100px" label-position="right" style="min-width: 600px;">

						<el-form-item label="ID:">
							<div>{{edit_params.id}}</div>
						</el-form-item>

						<el-form-item label="套餐名称:">
							<el-input style="width: 218px;" v-model="edit_params.name"></el-input>
						</el-form-item>

						<el-form-item label="价格:">
							<el-input style="width: 218px;" v-model="edit_params.price"></el-input>
						</el-form-item>

						<el-form-item label="次数:">
							<el-input style="width: 218px;" v-model="edit_params.package_num"></el-input>
						</el-form-item>

						<el-form-item label="活动介绍:">
							<el-input style="width: 218px;" v-model="edit_params.desc"></el-input>
						</el-form-item>

						<el-form-item label="类型:">
							<el-select v-model="edit_params.package_type" placeholder="请选择套餐类型">
								<el-option v-for="item in type_options" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="">
							<el-button clearable icon="el-icon-thumb" size="small" type="primary" @click="EditPackage">更新信息</el-button>
						</el-form-item>

					</el-form>

				</el-dialog>
                
                
                
                <el-dialog title="编辑AI套餐" :visible.sync="ai_editVisible" append-to-body style="min-width: 510px; max-width: 910px; margin: 0 auto;">
                
                	<el-form label-width="100px" label-position="right" style="min-width: 600px;">
                
                		<el-form-item label="ID:">
                			<div>{{ai_edit_params.id}}</div>
                		</el-form-item>
                        
                        <el-form-item label="套餐名称:">
                        	<el-input style="width: 218px;" v-model="ai_edit_params.name"></el-input>
                        </el-form-item>
                                        
                        <el-form-item label="价格:">
                        	<el-input style="width: 218px;" v-model="ai_edit_params.price"></el-input>
                        </el-form-item>
                        
                        <el-form-item label="套餐时间:">
                        	<el-input style="width: 218px;" v-model="ai_edit_params.time"></el-input>
                        </el-form-item>
                        
                        <el-form-item label="时间类型:">
                        	<el-select v-model="ai_edit_params.time_type" placeholder="请选择时间类型">
                        		<el-option v-for="item in time_options" :key="item.value" :label="item.label" :value="item.value">
                        		</el-option>
                        	</el-select>
                        </el-form-item>
                                        
                        <el-form-item label="活动介绍:">
                        	<el-input style="width: 218px;" v-model="ai_edit_params.desc"></el-input>
                        </el-form-item>
                                        
                        <el-form-item label="状态:">
                        	<el-select v-model="ai_edit_params.state" placeholder="套餐状态">
                        		<el-option v-for="item in state_options" :key="item.value" :label="item.label" :value="item.value">
                        		</el-option>
                        	</el-select>
                        </el-form-item>
                                        
                        <el-form-item label="">
                        	<el-button clearable icon="el-icon-thumb" size="small" type="primary" @click="EditAiPackage">更新信息</el-button>
                        </el-form-item>
                        
                	</el-form>
                
                </el-dialog>


				

			</div>
		</el-card>


	</el-row>

</template>

<script>
	import HTTP from '@/request/admin/api'

	export default {
		data() {
			return {
                active: 'first',
				data: [],
				rank_data: [],
				
				params: {
					page: 1,
					limit: 10,
					username: ''
				},

				set_params: {
					id: '',
					balance: '',
					username: '',
				},

				setVisible: false,
				package_params: {
					page: 1,
					limit: 10,
					name: ''
				},
				package: [],
				edit_params: {
					id: '',
					name: '',
					package_type: '',
					price: '',
					package_num: '',
					desc: '',
				},
				add_params: {
					name: '',
					package_type: '',
					price: '',
					package_num: '',
					desc: '',
				},
				editVisible: false,
				dialogFormVisible: false,
				type_options: [{
						value: 'video',
						label: '视频翻译'
					},
					{
						value: 'photo',
						label: '图片翻译'
					}
				],
                
                ai_package_params: {
                	page: 1,
                	limit: 10,
                	name: ''
                },
                ai_data: [],
                ai_addVisible: false,
                ai_editVisible: false,
                ai_add_params: {
                	name: '',
                	time_type: '',
                	price: '',
                	time: '',
                	desc: '',
                    state: ''
                },
                ai_edit_params: {
                    id: '',
                	name: '',
                	time_type: '',
                	price: '',
                	time: '',
                	desc: '',
                    state: ''
                },
                time_options: [{
                		value: 'day',
                		label: '天'
                	},
                	{
                		value: 'month',
                		label: '月'
                	},
                    {
                    	value: 'year',
                    	label: '年'
                    },
                ],
                state_options: [{
                		value: 'false',
                		label: '停止'
                	},
                	{
                		value: 'true',
                		label: '启用'
                	},
                ],
                
			}
		},
		mounted() {

			this.GetPackage()
            this.GetAiPackage()
		},

		methods: {

			// 获取套餐列表
			GetPackage: function() {
				HTTP.getPackage(this.package_params).then(res => {
					this.package = res
				})
			},

			// 编辑套餐信息
			EditViewInfo: function(row) {
				this.edit_params.id = row.id
				this.edit_params.name = row.name
				this.edit_params.price = row.price
				this.edit_params.desc = row.desc
				this.edit_params.package_num = row.package_num
				this.edit_params.package_type = row.package_type
				this.editVisible = true
			},
			
			EditPackage: function(){
				HTTP.editPackage(this.edit_params).then(res=>{
					this.$message.success(res.message)
					this.editVisible = false
					this.GetPackage()
				})
			},

			// 添加套餐
			AddPackage: function() {
				HTTP.AddPackage(this.add_params).then(res => {
					this.$message.success(res.message)
					this.dialogFormVisible = false
					this.GetPackage()
				})
			},
            
            
            // 获取AI配音套餐
            GetAiPackage: function(){
                HTTP.getAiVoicePackage(this.ai_package_params).then(res=>{
                    this.ai_data = res
                })
            },
            // 添加AI配音套餐
            AddAiPackage: function(){
                HTTP.addAiVoicePackage(this.ai_add_params).then(res=>{
                    this.$message.success(res.message)
                    this.GetAiPackage()
                }) 
            },
            
            // 编辑AI套餐信息
            EditAIViewInfo: function(row) {
            	this.ai_edit_params.id = row.id
            	this.ai_edit_params.name = row.name
            	this.ai_edit_params.time_type = row.time_type
            	this.ai_edit_params.time = row.time
            	this.ai_edit_params.state = row.state.toString()
                this.ai_edit_params.price = row.price
                this.ai_edit_params.desc = row.desc
            	this.ai_editVisible = true
            },
            
            EditAiPackage: function(){
                HTTP.editAiVoicePackage(this.ai_edit_params).then(res=>{
                    this.$message.success(res.message)
                    this.GetAiPackage()
                    this.ai_editVisible = false
                })
            },

			handleSizeChange(value) {
				this.package_params.limit = value
				this.GetPackage()
			},
			handleCurrentChange(value) {
				this.package_params.page = value
				this.GetPackage()
			},
		}
	}
</script>

<style scoped>
	.el-row {
		text-align: left;
	}

	.user-box {
		background: #fff;
		height: 100px;
		padding: 20px;
		margin-bottom: 20px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
	}

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.box-card {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		color: #000000d9;
		font-size: 14px;
		font-variant: tabular-nums;
		line-height: 1.5715;
		list-style: none;
		font-feature-settings: "tnum";
		position: relative;
		background: #fff;
		border-radius: 10px;
	}

	/deep/.dialog-box-card {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		color: #000000d9;
		font-size: 14px;
		font-variant: tabular-nums;
		line-height: 1.5715;
		list-style: none;
		font-feature-settings: "tnum";
		position: relative;
		background: #fff;
		border-radius: 10px;
		min-width: 520px;
		max-width: 520px;
		margin: 0 auto;
	}

	/deep/ .el-dialog__header {
		padding: 16px 24px;
		color: #000000d9;
		border-bottom: 1px solid #f0f0f0;
	}
</style>
