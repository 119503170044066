<template>
	<div class="main">
		<div class="login_box">
			<div class="pic">
				<img src="https://resource-1254463213.cos.ap-shanghai.myqcloud.com/linkstores/2020-11-13/of79yrvz9cmfqd2hpbzv.jpg">
			</div>
			<div class="log">
				<div class="wrapper">
					<div class="login_row" style="margin-bottom: 40px;"></div>
					<div class="login_row">
						<span>云象翻译 用户登录</span>
					</div>

					<el-form ref="loginFormRef" :model="loginForm" :rules="loginRules" style="margin-top: 20px;">
						<el-form-item prop="username">
							<el-input v-model="loginForm.username" prefix-icon="iconfont icon-user" placeholder="登录账号 / 手机"></el-input>
						</el-form-item>

						<!-- 密码 -->
						<el-form-item prop="password">
							<el-input v-model="loginForm.password" prefix-icon="iconfont icon-mima" show-password placeholder="登录密码"></el-input>
						</el-form-item>

						<!-- 按钮 -->
						<el-form-item class="btns">
							<el-button type="primary" style="width: 100%;" @click="submit">立即登录</el-button>
						</el-form-item>

						<el-form-item class="btns">
							<el-checkbox-group>

								<div style="width: 40%; float: left;">
									<el-switch active-color="#13ce66" active-text="我已阅读用户协议" inactive-color="#ff4949">
									</el-switch>
								</div>

								<div style="width: 60%;float: right;text-align: right;">
									<!-- <el-link type="primary" href="http://www.3482.cn/danye/3.html" target="_blank">忘记密码</el-link>
									<el-link type="primary" style="margin:0px 15px;"> | </el-link> -->
									<el-link type="primary" @click="$router.push({name: 'Register'})">立即注册账号</el-link>
								</div>
							</el-checkbox-group>
						</el-form-item>

						<el-alert :closable="false" style="margin: 20px 0px;" title="注 : 点击登录即表明已阅读本站用户协议" type="info" show-icon>
						</el-alert>

						<el-alert :closable="false" title="温馨提示: 登录账号时请保护您的账号安全, 切勿泄露信息他人. 防止账号被盗用! 如发现账号被他人登录, 请及时更改密码保障账号安全! 必要时可联系客服冻结账号!" type="warning"
						 style="text-align: left;" show-icon>
						</el-alert>

					</el-form>




				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import HTTP from '@/request/api'

	export default {
		data() {
			return {
				loginRules: {
					username: [{
						required: true,
						message: "请输入用户账号",
						trigger: 'blur'
					}, {
						min: 3,
						max: 50,
						message: "用户名长度在 3-50个字符",
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: "请输入用户密码",
						trigger: 'blur'
					}, {
						min: 3,
						max: 20,
						message: "密码长度在 3 - 20 个字符",
						trigger: 'blur'
					}]
				},
				loginForm: {
					username: '',
					password: '',
				}
			}
		},
		methods: {
			submit: function() {
				this.$refs.loginFormRef.validate(async valid => {
					if (!valid) return;
					HTTP.auth(this.loginForm).then(res => {
						this.$message.success('登录成功')
						window.sessionStorage.setItem('token', res.data)
						this.$router.push('/user')
					})
				})
			}
		}

	}
</script>

<style scoped>
	.main {
		height: 100%;
		width: 100%;
		position: fixed;
		background-image: url("https://resource-1254463213.cos.ap-shanghai.myqcloud.com/linkstores/2020-11-13/7u3uaq80yd4kwuzrn5jn.jpg");
		background-size: 100%;
	}


	.login_box {
		width: 60%;
		min-height: 760px;
		background-color: gainsboro;
		height: calc(100% - 200px);
		margin: 0 auto;
		box-shadow: 0.25rem 0.25rem 0.375rem 0.25rem grey;
		border-radius: 1.25rem;
		position: absolute;
		top: 6.5625rem;
		left: 50%;
		transform: translateX(-50%);
	}

	.pic {
		float: left;
		width: 50%;
		height: 100%;
	}

	.pic img {
		border-radius: 1.25rem 0 0 1.25rem;
		height: 100%;
		width: 100%;
	}

	.log {
		float: right;
		width: 50%;
		height: 100%;
		border-radius: 0px 1.25rem 1.25rem 0px;
		background-color: white;
	}

	.wrapper {
		height: 100%;
		width: calc(100% - 80px);
		padding: 0px 40px;
		border-radius: 0px 1.25rem 1.25rem 0px;
		/* background-color: #409EFF; */
	}

	.login_row {
		/* background-color: #42B983; */
		height: 3.125rem;
		/* display: block; */
		/* position: relative */
		/* padding: 10px 0px; */
	}

	.mg15 {
		margin: 15px 0px;
		display: flex;
	}

	.login_row span {
		text-align: left;
		line-height: 3.125rem;
		font-size: 32px;
	}

	@media screen and (max-width: 1460px) {
		.log {
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 1.25rem 1.25rem 1.25rem 1.25rem;
			background-color: white;
		}

		.login_box {
			width: 60%;
			top: calc(100% / 2 - 500);
			min-height: 0;
		}
	}

	@media screen and (max-width: 1260px) {
		.log {
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 1.25rem 1.25rem 1.25rem 1.25rem;
			background-color: white;
		}

		.login_box {
			width: 60%;
			top: calc(100% / 2 - 500);
			min-height: 0;
		}
	}

	@media screen and (max-width: 960px) {
		.log {
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 1.25rem 1.25rem 1.25rem 1.25rem;
			background-color: white;
		}

		.login_box {
			width: 70%;
			top: calc(100% / 2 - 500);
			min-height: 0;
		}
	}

	@media screen and (max-width: 768px) {
		.log {
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 1.25rem 1.25rem 1.25rem 1.25rem;
			background-color: white;
		}

		.login_box {
			width: 100%;
			top: calc(100% / 2 - 500);
			min-height: 0;
			box-shadow: none;
		}

		.login_row {
			height: auto;
		}

		.pic {
			display: none;
		}

		.main {
			background: 0;
		}

		.wrapper {
			width: calc(100% - 40px);
			padding: 0px 20px;
		}
	}
</style>
