import request from './server.js'

const HTTP = {
	
	// 管理登录
	adminAuth(data){
		return request({
			url: 'admin/login',
			method: 'POST',
			data: data
		})
	},

	// 获取管理信息
	admininfo(){
		return request({
			url: 'admin/dashboard',
			method: 'GET'
		}, true)
	},
	
	// 获取用户列表
	userList(data){
		return request({
			url: 'admin/userlist',
			method: 'GET',
			params: data
		}, true)
	},

	// 设置用户
	userSet(data){
		return request({
			url: 'admin/userlist',
			method: 'PUT',
			data: data
		}, true)
	},
	// 设置用户信息
	userSetinfo(data){
		return request({
			url: 'admin/userlist',
			method: 'PATCH',
			data: data
		}, true)
	},
	// 添加用户
	userAdd(data){
		return request({
			url: 'admin/userlist',
			method: 'POST',
			data: data
		}, true)
	},
	
	// 获取充值记录
	userRechargeList(data){
		return request({
			url: 'admin/recharge',
			method: 'GET',
			params: data
		}, true)
	},
	
	// 获取账变记录
	userRecord(data){
		return request({
			url: 'admin/record',
			method: 'GET',
			params: data
		}, true)
	},
	// 获取登录日志记录
	userLoginLog(data){
		return request({
			url: 'admin/loglist',
			method: 'GET',
			params: data
		}, true)
	},
	// 删除登录日志记录
	userLoginLogDel(data){
		return request({
			url: 'admin/loglist',
			method: 'DELETE',
			data: data
		}, true)
	},

	// 视频翻译列表
	userVideoList(data){
		return request({
			url: 'admin/video/list',
			method: 'GET',
			params: data
		}, true)
	},
	
	// 获取渲染视频下载
	userVideoDown(data){
		return request({
			url: 'admin/video/down',
			method: 'POST',
			data
		}, true)
	},
	
	// 刷新视频状态
	userVideoRefresh(data){
		return request({
			url: 'admin/video/refresh',
			method: 'POST',
			data
		}, true)
	},
	
	// 获取视频编辑
	userVideoEdit(data){
		return request({
			url: 'admin/video/edit',
			method: 'POST',
			data
		}, true)
	},
	
	// 获取套餐列表
	getPackage(data){
		return request({
			url: 'admin/package',
			method: 'GET',
			params: data
		}, true)
	},
	// 编辑套餐信息
	editPackage(data){
		return request({
			url: 'admin/package',
			method: 'PUT',
			data: data
		}, true)
	},
	// 新增套餐
	AddPackage(data){
		return request({
			url: 'admin/package',
			method: 'POST',
			data: data
		}, true)
	},
	// 获取公告列表
	getNoticeList(data){
		return request({
			url: 'admin/notice',
			method: 'GET',
			params: data
		}, true)
	},
	// 添加公告
	AddNotice(data){
		return request({
			url: 'admin/notice',
			method: 'POST',
			data: data
		}, true)
	},
	// 编辑公告
	editNotice(data){
		return request({
			url: 'admin/notice',
			method: 'PUT',
			data: data
		}, true)
	},
	// 删除公告
	delNotice(data){
		return request({
			url: 'admin/notice',
			method: 'DELETE',
			data: data
		}, true)
	},
	// 查询购买套餐记录
	getPackageRecordList(data){
		return request({
			url: 'admin/record_list',
			method: 'GET',
			params: data
		}, true)
	},
    
    // 查询配音列表
    getVoiceList(data){
        return request({
            url: 'admin/voicelist',
            method: 'GET',
            params: data
        }, true)
    },
	
    // 删除配音记录
    delVoice(data){
        return request({
            url: 'admin/voicelist',
            method: 'DELETE',
            data: data
        }, true)
    },
    
    // 获取配音套餐
    getVoicePackage(){
        return request({
            url: 'admin/voicepack',
            method: 'GET',          
        }, true)
    },
    
    // AI配音套餐列表
    getAiVoicePackage(data){
        return request({
            url: 'admin/peiyin',
            method: 'GET',
            params: data
        }, true)
    },
    
    // AI配音添加套餐
    addAiVoicePackage(data){
        return request({
            url: 'admin/peiyin',
            method: 'POST',
            data: data
        }, true)
    },
    
    // AI配音套餐编辑
    editAiVoicePackage(data){
        return request({
            url: 'admin/peiyin',
            method: 'PUT',
            data: data
        }, true)
    },
    
    // 发起授权登录请求
    aiLogin(){
        return request({
            url: 'admin/ai/auth',
            method: 'GET'
        }, true)
    },
    
    // 获取登录状态
    aiLoginState(data){
        return request({
            url: 'admin/ai/auth',
            method: 'POST',
            data:data
        }, true)
    }
    
}


module.exports = HTTP;
