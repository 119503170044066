<template>
	<div class="main">
		<div class="reg_box">
			<div class="pic">
				<img src="https://resource-1254463213.cos.ap-shanghai.myqcloud.com/linkstores/2020-11-13/of79yrvz9cmfqd2hpbzv.jpg">
			</div>
			<div class="log">
				<div class="wrapper">
					<div class="reg_row" style="margin-bottom: 40px;"></div>
					<div class="reg_row">
						<span>云象翻译 注册中心</span>
					</div>

					<el-form ref="RegFormRef" :model="params" :rules="RegRules" style="margin-top: 20px;">
						<el-form-item prop="username">
							<el-input v-model="params.username" prefix-icon="iconfont icon-user" placeholder="请输入用户名" onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"></el-input>
						</el-form-item>


						<el-form-item prop="phone">
							<el-input v-model="params.phone" prefix-icon="iconfont icon-dianhua" placeholder="请输入手机号码"></el-input>
						</el-form-item>

						<el-form-item prop="code">
							<el-input v-model="params.code" prefix-icon="iconfont icon-yanzhengma" placeholder="请输入短信验证码" style="width: 60%;margin-right: 3.4%;"></el-input>
							<el-button style="width: 36.5555%;" type="primary" :disabled="disable" :class="{ codeGeting:isGeting }" @click="GetVerifyCode">{{ getCode }}</el-button>
						</el-form-item>


						<!-- 密码 -->
						<el-form-item prop="password">
							<el-input v-model="params.password" prefix-icon="iconfont icon-mima" show-password placeholder="请输入密码"></el-input>
						</el-form-item>

						<el-form-item prop="changpass">
							<el-input v-model="params.changpass" prefix-icon="iconfont icon-mima" show-password placeholder="请再次输入确认密码"></el-input>
						</el-form-item>

						<!-- 按钮 -->
						<el-form-item class="btns">
							<el-button type="primary" style="width: 100%;" @click="submit">立即注册</el-button>
						</el-form-item>

						<el-form-item class="btns">
							<el-checkbox-group>

								<div style="width: 40%; float: left;">
									<el-switch active-color="#13ce66" active-text="我已阅读用户协议" inactive-color="#ff4949">
									</el-switch>
								</div>

								<div style="width: 60%;float: right;text-align: right;">

									<el-link type="primary" @click="$router.push({name: 'Login'})">已有账号返回登录</el-link>
								</div>
							</el-checkbox-group>
						</el-form-item>

					</el-form>




				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import HTTP from '@/request/api'
	export default {
		data() {
			return {
				isGeting: false,
				getCode: "获取验证码",
				disable: false,
				count: 60,
				params: {
					username: '',
					password: '',
					changpass: '',
					phone: '',
					code: '',
				},
				RegRules: {
					username: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}, {
						min: 3,
						max: 50,
						message: '用户名长度不能小于3 且不能大于50个字符',
						trigger: 'blur'
					}],
					password: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							min: 3,
							max: 20,
							message: "密码长度不能小于3 且不能大于20个字符",
							trigger: 'blur'
						}
					],
					changpass: [{
							required: true,
							message: '请再次输入密码',
							trigger: 'blur'
						},
						{
							min: 3,
							max: 20,
							message: "密码长度不能小于3 且不能大于20个字符",
							trigger: 'blur'
						}
					],
					code: [{
						required: true,
						message: '请输入短信验证码',
						trigger: 'blur'
					}, ],
					phone: [{
							required: true,
							message: '请输入手机号码',
							trigger: 'blur'
						},
						{
							type: 'number',
							message: '请输入正确的手机号码',
							trigger: 'blur',
							transform(value) {
								var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
								if (!phonereg.test(value)) {
									return false
								} else {

								}
							}
						}
					],
				}
			}
		},
		methods: {
			submit: function() {
				this.$refs.RegFormRef.validate(async valid =>{
					if(!valid) return;
					if(this.params.password != this.params.changpass){
						this.$message.error("两次密码不正确，请检查！")
						return;
					}
					HTTP.register(this.params).then(res=>{
						this.$message.success(res.message)
						this.$router.push({
							name: 'Login'
						})
					})
					
				})
			},
			GetVerifyCode: function() {
				let data = {
					phone: this.params.phone,
				}
				HTTP.sendsms(data).then(res=>{
					this.$message.success(res.message)
					// 定时
					var countDowm = setInterval(()=>{
						if(this.count < 1){
							this.isGeting = false
							this.disable = false
							this.getCode = "获取验证码"
							this.count = 60
							clearInterval(countDowm)
						}else{
							this.isGeting = true
							this.disable = true
							this.getCode = this.count-- + "s后再获取"
						}
					}, 1000)
				})
			}
		}
	}
</script>

<style scoped>
	.main {
		height: 100%;
		width: 100%;
		position: fixed;
		background-image: url("https://resource-1254463213.cos.ap-shanghai.myqcloud.com/linkstores/2020-11-13/7u3uaq80yd4kwuzrn5jn.jpg");
		background-size: 100%;
	}


	.reg_box {
		width: 60%;
		background-color: gainsboro;
		height: calc(100% - 200px);
		margin: 0 auto;
		box-shadow: 0.25rem 0.25rem 0.375rem 0.25rem grey;
		border-radius: 1.25rem;
		position: absolute;
		top: 6.5625rem;
		left: 50%;
		transform: translateX(-50%);
	}

	.pic {
		float: left;
		width: 50%;
		height: 100%;
	}

	.pic img {
		border-radius: 1.25rem 0 0 1.25rem;
		height: 100%;
		width: 100%;
	}

	.log {
		float: right;
		width: 50%;
		height: 100%;
		border-radius: 0px 1.25rem 1.25rem 0px;
		background-color: white;
	}

	.wrapper {
		height: 100%;
		width: calc(100% - 80px);
		padding: 0px 40px;
		border-radius: 0px 1.25rem 1.25rem 0px;
		/* background-color: #409EFF; */
	}

	.reg_row {
		/* background-color: #42B983; */
		height: 3.125rem;
		/* display: block; */
		/* position: relative */
		/* padding: 10px 0px; */
	}

	.mg15 {
		margin: 15px 0px;
		display: flex;
	}

	.reg_row span {
		text-align: left;
		line-height: 3.125rem;
		font-size: 32px;
	}
	
	@media screen and (max-width: 1460px) {
		.log {
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 1.25rem 1.25rem 1.25rem 1.25rem;
			background-color: white;
		}
		.reg_box {
			width: 50%;
		}
	}

	@media screen and (max-width: 1260px) {
		.log {
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 1.25rem 1.25rem 1.25rem 1.25rem;
			background-color: white;
		}
		.reg_box {
			width: 50%;
		}
	}

	@media screen and (max-width: 960px) {
		.log {
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 1.25rem 1.25rem 1.25rem 1.25rem;
			background-color: white;
		}
		.reg_box {
			width: 70%;
		}
	}

	@media screen and (max-width: 768px) {
		.log {
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 1.25rem 1.25rem 1.25rem 1.25rem;
			background-color: white;
		}
		
		.reg_box {
			width: 100%;
			top: calc(100% / 2 - 500);
			min-height: 0;
			box-shadow: none;
		}
		.reg_row{
			height: auto;
		}
		.pic{
			display: none;
		}
		.main{
			background: 0;
		}
		.wrapper{
			width: calc(100% - 40px);
			padding: 0px 20px;
		}
	}
</style>
