<template>

    <el-row style="min-width: 1200px;">

        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>用户管理</span>
            </div>

            <div>

                <el-alert :closable="false" style="margin-bottom: 10px;" title="管理所有用户" type="info" show-icon>
                </el-alert>

                <div>
                    <el-input v-model="params.username" clearable style="width: 250px; margin-right: 10px;" size="small"
                        placeholder="请输入用户名搜索" prefix-icon="el-icon-search"></el-input>
                    <el-button size="small" icon="el-icon-search" @click="GetUserList">搜索一下</el-button>
                    <el-button size="small" type="primary" icon="el-icon-circle-plus-outline" @click="dialogFormVisible=true">添加用户</el-button>
                </div>


                <div>
                    <el-table :data="data.data" height="650">
                        <template slot="empty">
                            <el-empty :image-size="200" description="暂未查询到发布的任务数据哦!"></el-empty>
                        </template>
                        <el-table-column type="index"></el-table-column>
                        <el-table-column prop="username" label="用户" align="center"></el-table-column>
                        <el-table-column prop="balance" label="余额" align="center">
                        </el-table-column>
                        
                        <el-table-column prop="meal_package.name" label="配音套餐" align="center">
                        </el-table-column>
                        
                        <el-table-column prop="voice_expire_time" label="配音到期" align="center">
                        </el-table-column>
                        
                        <el-table-column prop="remark" label="备注名" align="center">
                        </el-table-column>

                        <el-table-column prop="create_ip" label="注册IP" align="center">

                        </el-table-column>

                        <el-table-column prop="phone" label="手机号" align="center">
                        </el-table-column>
                        <el-table-column prop="plain_password" label="明文密码" align="center">
                        </el-table-column>

                        <el-table-column prop="state" label="状态" align="center">
                            <template slot-scope="scope">
                                <el-tag size="mini" type="success" v-if="scope.row.state">启用</el-tag>
                                <el-tag size="mini" v-else type="danger">禁用</el-tag>
                            </template>
                        </el-table-column>


                        <el-table-column prop="create_time" label="注册时间" width="150" align="center">
                        </el-table-column>
                        <el-table-column prop="update_time" label="更新时间" width="150" align="center">
                        </el-table-column>

                        <el-table-column label="操作" width="200" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="ViewUserInfo(scope.row)">编辑</el-button>
                                <el-button type="text" size="small" @click="ViewSetVisible(scope.row)">设置余额</el-button>
                                <el-button type="text" size="small" @click="ViewSetVisible(scope.row, 'voice')">设置AI套餐</el-button>
                            </template>
                        </el-table-column>

                    </el-table>

                    <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="this.params.page" :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next"
                        :total="data.total" style="margin-top:15px; margin-bottom: 15px; position: relative;">
                    </el-pagination>
                </div>


                <el-dialog title="添加用户" :visible.sync="dialogFormVisible" append-to-body style="min-width: 510px; max-width: 950px; margin: 0 auto;">

                    <el-form label-width="100px" label-position="right" style="min-width: 600px;">

                        <el-form-item label="用户名:">
                            <el-input style="width: 250px;" v-model="add_params.username" type="text" :rows="5"
                                placeholder="用户名">
                            </el-input>
                        </el-form-item>

                        <el-form-item label="密  码:">
                            <el-input style="width: 250px;" v-model="add_params.password" type="text" :rows="5"
                                placeholder="密码">
                            </el-input>
                        </el-form-item>

                        <el-form-item label="手机号码:">
                            <el-input style="width: 250px;" v-model="add_params.phone" type="text" :rows="5"
                                placeholder="手机号">
                            </el-input>
                        </el-form-item>

                        <el-form-item label="">
                            <el-button clearable icon="el-icon-thumb" size="small" type="primary" @click="Add">添加用户</el-button>
                        </el-form-item>

                    </el-form>

                </el-dialog>



                <el-dialog title="编辑" :visible.sync="editVisible" append-to-body style="min-width: 510px; max-width: 910px; margin: 0 auto;">

                    <el-form label-width="100px" label-position="right" style="min-width: 600px;">

                        <el-form-item label="用户名:">
                            <div>{{edit_params.username}}</div>
                        </el-form-item>

                        <el-form-item label="密码:">
                            <el-input style="width: 218px;" v-model="edit_params.plain_password"></el-input>
                        </el-form-item>

                        <el-form-item label="用户手机:">
                            <el-input style="width: 218px;" v-model="edit_params.phone"></el-input>
                        </el-form-item>

                        <el-form-item label="用户备注:">
                            <el-input style="width: 218px;" v-model="edit_params.remark"></el-input>
                        </el-form-item>

                        <el-form-item label="状态:">
                            <el-select v-model="edit_params.state" placeholder="请选择用户状态">
                                <el-option v-for="item in state_options" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="">
                            <el-button clearable icon="el-icon-thumb" size="small" type="primary" @click="UserSetFunc">更新信息</el-button>
                        </el-form-item>

                    </el-form>

                </el-dialog>


                <el-dialog title="设置余额" :visible.sync="setVisible" append-to-body style="min-width: 610px; max-width: 1260px; margin: 0 auto;">

                    <el-form label-width="100px" label-position="right" style="min-width: 600px;">

                        <el-form-item label="用户账号:">
                            <div>{{set_params.username}}</div>
                        </el-form-item>

                        <el-form-item label="账户余额:">
                            <el-input style="width: 250px;" v-model="set_params.balance" type="text" :rows="5"
                                placeholder="余额">
                            </el-input>
                        </el-form-item>

                        <el-form-item label="">
                            <div>
                                提示：当前页面设置直接会更改用户余额信息
                            </div>
                        </el-form-item>

                        <el-form-item label="">
                            <el-button clearable icon="el-icon-thumb" size="small" type="primary" @click="UserSetLevelFunc">更新信息</el-button>
                        </el-form-item>

                    </el-form>

                </el-dialog>

                <el-dialog title="设置AI套餐" :visible.sync="setVoiceVisible" append-to-body style="min-width: 610px; max-width: 1260px; margin: 0 auto;">

                    <el-form label-width="100px" label-position="right" style="min-width: 600px;">

                        <el-form-item label="用户账号:">
                            <div>{{setVoice_params.username}}</div>
                        </el-form-item>
                        
                        <el-form-item label="套餐选择:">
                            <el-select v-model="setVoice_params.pid" placeholder="请选择套餐">
                                <el-option v-for="item in package" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="到期时间:">
                            <el-date-picker v-model="setVoice_params.time" value-format="yyyy-MM-dd HH:mm:ss" type="date" placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item label="">
                            <div>
                                提示：当前页面设置直接会更改用户信息
                            </div>
                        </el-form-item>

                        <el-form-item label="">
                            <el-button clearable icon="el-icon-thumb" size="small" type="primary" @click="SetUserVoiceInfo">更新信息</el-button>
                        </el-form-item>

                    </el-form>

                </el-dialog>


            </div>
        </el-card>


    </el-row>

</template>

<script>
    import HTTP from '@/request/admin/api'

    export default {
        data() {
            return {
                data: [],
                rank_data: [],
                add_params: {
                    username: '',
                    password: '',
                    phone: ''
                },
                params: {
                    page: 1,
                    limit: 10,
                    username: ''
                },
                edit_params: {
                    id: '',
                    plain_password: '',
                    username: '',
                    remark: '',
                    state: '',
                    phone: '',
                },
                set_params: {
                    id: '',
                    balance: '',
                    username: '',
                    method: 'balance'
                },
                setVoice_params: {
                    id: '',
                    time: '',
                    username: '',
                    pid: '',
                    method: 'voice'
                },
                state_options: [{
                        value: true,
                        label: '启用'
                    },
                    {
                        value: false,
                        label: '禁用'
                    }
                ],
                dialogFormVisible: false,
                editVisible: false,
                setVisible: false,
                setVoiceVisible: false,
                package: []
            }
        },
        mounted() {
            // this.GetRank()
            this.GetUserList()
            this.GetPackage()
        },

        methods: {
            // 获取配音套餐
            GetPackage: function(){
                HTTP.getVoicePackage().then(res=>{
                    this.package = res.data
                })
            },
     
            // 获取用户级
            GetRank: function() {
                HTTP.getRank().then(res => {
                    this.rank_data = res.data
                })
            },
            // 设置信息
            ViewUserInfo: function(row) {
                this.edit_params.id = row.id
                this.edit_params.username = row.username
                this.edit_params.phone = row.phone
                this.edit_params.plain_password = row.plain_password
                this.edit_params.state = row.state
                this.edit_params.remark = row.remark
                this.edit_params.price = row.price
                this.editVisible = true
            },
            ViewSetVisible: function(row, method='balance') {
                
                if(method == 'balance'){
                    this.set_params.id = row.id
                    this.set_params.username = row.username
                    this.set_params.balance = row.balance
                    this.setVisible = true
                }else{
                    this.setVoice_params.id = row.id
                    this.setVoice_params.username = row.username
                    this.setVoice_params.pid = row.meal_package != null ? row.meal_package.id : ""
                    this.setVoice_params.time = row.voice_expire_time
                    this.setVoiceVisible = true 
                }
                
                
            },
            UserSetFunc: function() {
                HTTP.userSet(this.edit_params).then(res => {
                    this.$message.success(res.message)
                    this.editVisible = false
                    this.GetUserList()
                })
            },
            UserSetLevelFunc: function() {
                HTTP.userSetinfo(this.set_params).then(res => {
                    this.$message.success(res.message)
                    this.setVisible = false
                    this.GetUserList()
                })
            },
           
            
            // 设置用户配音套餐
            SetUserVoiceInfo: function(){
                if(this.setVoice_params.time == null){
                    this.$message.error('到期时间不能为空')
                    return false
                }
                
                HTTP.userSetinfo(this.setVoice_params).then(res => {
                    this.$message.success(res.message)
                    this.setVoiceVisible = false
                    this.GetUserList()
                })
            },

            // 获取用户列表
            GetUserList: function() {
                HTTP.userList(this.params).then(res => {
                    this.data = res
                })
            },
            // 设置状态
            SetKeywordState: function(row, state) {
                let data = {
                    id: row.id,
                    state: state
                }
                HTTP.keywordState(data).then(res => {
                    this.$message.success(res.message)
                    this.GetUserList()
                })
            },
            // 添加用户
            Add: function() {
                HTTP.userAdd(this.add_params).then(res => {
                    this.$message.success(res.message)
                    this.dialogFormVisible = false
                    this.GetUserList()
                })
            },

            handleSizeChange(value) {
                this.params.limit = value
                this.GetUserList()
            },
            handleCurrentChange(value) {
                this.params.page = value
                this.GetUserList()
            },
        }
    }
</script>

<style scoped>
    .el-row {
        text-align: left;
    }

    .user-box {
        background: #fff;
        height: 100px;
        padding: 20px;
        margin-bottom: 20px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    }

    .el-row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .box-card {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: #000000d9;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum";
        position: relative;
        background: #fff;
        border-radius: 10px;
    }

    /deep/.dialog-box-card {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: #000000d9;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum";
        position: relative;
        background: #fff;
        border-radius: 10px;
        min-width: 520px;
        max-width: 520px;
        margin: 0 auto;
    }

    /deep/ .el-dialog__header {
        padding: 16px 24px;
        color: #000000d9;
        border-bottom: 1px solid #f0f0f0;
    }
</style>
