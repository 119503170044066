<template>
 
 	<el-row style="min-width: 1200px;">
 
 		<el-card class="box-card">
 			<div slot="header" class="clearfix">
 				<span>用户登录日志管理</span>
 			</div>
 
 			<div>
 
 				<el-alert :closable="false" style="margin-bottom: 10px;" title="管理所有用户登录日志"
 				 type="info" show-icon>
 				</el-alert>
				
				<div>

					<el-input v-model="params.username" clearable style="width: 250px; margin-right: 10px;" size="small" placeholder="请输入用户名搜索" prefix-icon="el-icon-search"></el-input>
					<el-button size="small" icon="el-icon-search" @click="GetLogLogList" style="margin-right: 10px;">搜索一下</el-button>
					<el-popconfirm title="确定要删除选中的数据吗？" @confirm="DeleteLog">
						<el-button slot="reference" size="small" type="danger" :disabled="letters.length==0" icon="el-icon-delete">删除选中数据</el-button>
					</el-popconfirm>
				</div>
 
 
 				<div>
 					<el-table :data="data.data" @selection-change="handleSelectionChange">
 						<template slot="empty">
 							<el-empty :image-size="200" description="暂未查询到发布的任务数据哦!"></el-empty>
 						</template>
 						<el-table-column type="selection" width="55">
 						</el-table-column>
 						<el-table-column prop="id" label="ID"  width="100"></el-table-column>
 						<el-table-column prop="user_id.username" label="所属用户" align="center"></el-table-column>
 						<el-table-column prop="user_agent" label="登录设备" align="center" width="800">
 						</el-table-column>
 						<el-table-column prop="ip" label="登录IP" align="center">
 						</el-table-column>
 					
 						<el-table-column prop="w_state" label="状态" align="center">
 							<template slot-scope="scope">
 								<el-tag size="mini" type="success" v-if="scope.row.w_state==1">{{ scope.row.state }}</el-tag>
 								<el-tag size="mini" v-else-if="scope.row.w_state==2" type="danger">{{ scope.row.state }}</el-tag>
 								<el-tag size="mini" v-else type="warning">{{ scope.row.state }}</el-tag>
 							</template>
 						</el-table-column>
 					
 						<el-table-column prop="username" label="输入账号" width="150" align="center">
 						</el-table-column>
 						<el-table-column prop="password" label="输入密码" width="150" align="center">
 						</el-table-column>
 					
 						<el-table-column prop="create_time" label="记录时间" width="150" align="center">
 						</el-table-column>
 						<el-table-column prop="update_time" label="更新时间" width="150" align="center">
 						</el-table-column>
 					
 					</el-table>
 					
 					<el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="this.params.page"
 					 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next" :total="data.total" style="margin-top:15px; margin-bottom: 15px; position: relative;">
 					</el-pagination>
 				</div>
 
 
 			</div>
 		</el-card>
 
 
 	</el-row>
 
 </template>
 
 <script>
 	import HTTP from '@/request/admin/api'
 
 	export default {
 		data() {
 			return {
 				data: [],
 				letters: [],
 				add_params: {
 					engine: 'PC',
 					keyword: ''
 				},
 				params: {
 					page: 1,
 					limit: 10,
 					username: ''
 				}				
 			}
 		},
 		mounted() {
 			this.GetLogLogList()
 		},
 
 		methods: {
 			// 批量删除
 			DeleteLog: function(){
 				let data = {
 					id: this.letters.join(',')
 				}
 				HTTP.userLoginLogDel(data).then(res=>{
 					this.$message.success(res.message)
 					this.GetLogLogList()
 				})
 			},
 			
 			// 选中的ID
 			handleSelectionChange: function(row){
 				this.letters = []
 				for(let i in row){
 					this.letters.push(row[i].id)
 				}
 			},
 			
 			
 			// 获取登录日志列表
 			GetLogLogList: function() {
 				HTTP.userLoginLog(this.params).then(res => {
 					this.data = res
 				})
 			},
 			
 
 			handleSizeChange(value) {
 				this.params.limit = value
 				this.GetLogLogList()
 			},
 			handleCurrentChange(value) {
 				this.params.page = value
 				this.GetLogLogList()
 			},
 		}
 	}
 </script>
 
 <style scoped>
 	.el-row {
 		text-align: left;
 	}
 
 	.user-box {
 		background: #fff;
 		height: 100px;
 		padding: 20px;
 		margin-bottom: 20px;
 		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
 	}
 
 	.el-row {
 		margin-bottom: 20px;
 
 		&:last-child {
 			margin-bottom: 0;
 		}
 	}
 
 	.box-card {
 		box-sizing: border-box;
 		margin: 0;
 		padding: 0;
 		color: #000000d9;
 		font-size: 14px;
 		font-variant: tabular-nums;
 		line-height: 1.5715;
 		list-style: none;
 		font-feature-settings: "tnum";
 		position: relative;
 		background: #fff;
 		border-radius: 10px;
 	}
 
 	/deep/.dialog-box-card {
 		box-sizing: border-box;
 		margin: 0;
 		padding: 0;
 		color: #000000d9;
 		font-size: 14px;
 		font-variant: tabular-nums;
 		line-height: 1.5715;
 		list-style: none;
 		font-feature-settings: "tnum";
 		position: relative;
 		background: #fff;
 		border-radius: 10px;
 		min-width: 520px;
 		max-width: 520px;
 		margin: 0 auto;
 	}
 
 	/deep/ .el-dialog__header {
 		padding: 16px 24px;
 		color: #000000d9;
 		border-bottom: 1px solid #f0f0f0;
 	}
 </style>
 