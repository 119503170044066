import axios from 'axios'
import ENV from '../dev'
import qs from 'qs'
import { Message, Loading } from 'element-ui'

export default (params, isToken, isJson) => {
	let header = { ...params.headers }
	if(isToken){
		
		let token = window.sessionStorage.getItem('admin_token')
		if(token){
			header['Authorization'] = token		
		}
		
	}
	
	if(isJson){
		header['Content-Type'] = "application/json"
	}else{
		header['Content-Type'] = "application/x-www-form-urlencoded"
		params.data = qs.stringify(params.data)
	}
	
	
	return new Promise((resolve, reject) => {
		axios.request({
			...params,
			data: params.data,
			headers: header,
			url: params.customUrl || ENV.baseURL + params.url,
		}).then(res=>{
			resolve(res.data)
		}).catch(err=>{

			switch (err.response.status) {
				case 404:
				// console.log(err.response)
					// router.push('/admin/login')
					location.href = "/admin/login"
					break;
				case 401:
			
					Message.error("登录验证已失效，请重新登录!");
					
					location.href = "/admin/login"
					
					// router.replace({
					// 	path: '/login',
					// 	query: {
					// 		redirect: router.currentRoute.fullPath
					// 	}
					// })
					
					break;
				case 400:
					Message.error(err.response.data.message)
					break;
				default:
					Message.error(err.response.data.message)
					let loadingInstance = Loading.service(options);
					loadingInstance.close();
					break;
			}
			return Promise.reject(err.response.data)
		})
	})
	
}