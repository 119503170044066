<template>
    
	<div class="data-page">
		<div class="header-wrap">
			<!-- 头部NAR -->
			<div class="nav">
				<div class="nav-bar">
					<div class="logo">
						<a href="">
							<el-image style="height: 36px;" src="https://dev-data.geekerp.com/assets/logo-white.bdf38052.svg"></el-image>
						</a>
						<span>数据分析</span>
					</div>
					<div class="login-info">
						<div v-if="!is_login">
							<el-button size="small" type="warning" @click="open('login')">登录</el-button>
							<el-button size="small" icon="el-icon-present" type="primary" @click="open('reg')">立即注册</el-button>
						</div>
						<div v-else>
							<i class="el-icon-user"><span style="padding: 0px 10px;">{{userinfo.username}}</span></i>
							<el-button type="text" style="color: #fff;">控制台</el-button>
							<el-button type="text" style="color: #fff;" @click="logout">退出</el-button>
						</div>
					</div>
				</div>
			</div>

			<!-- 登录界面 -->
			<el-dialog :visible.sync="login_dialog" width="30%">

				<div style="font-size: 24px;">
					<span v-if="log_or_reg">用户登录</span>
					<span v-else>用户注册</span>
				</div>

				<el-form v-if="log_or_reg" ref="loginFormRef" :model="loginForm" :rules="loginRules" style="margin-top: 20px;">
					<el-form-item prop="username">
						<el-input v-model="loginForm.username" prefix-icon="iconfont icon-user" placeholder="用户名/手机号"></el-input>
					</el-form-item>

					<!-- 密码 -->
					<el-form-item prop="password">
						<el-input v-model="loginForm.password" prefix-icon="iconfont icon-mima" show-password placeholder="密码"></el-input>
					</el-form-item>

					<!-- 按钮 -->
					<el-form-item class="btns">
						<el-button type="primary" style="width: 100%;" @click="login">立即登录</el-button>
					</el-form-item>

					<el-form-item class="btns">
						<el-checkbox-group>

							<div style="width: 40%; float: left;">
								<el-switch active-color="#13ce66" active-text="我已阅读用户协议" inactive-color="#ff4949">
								</el-switch>
							</div>

							<div style="width: 60%;float: right;text-align: right;">
								<el-link type="primary" href="http://www.3482.cn/danye/3.html" target="_blank">忘记密码</el-link>
								<el-link type="primary" style="margin:0px 15px;"> | </el-link>
								<el-link type="primary" @click="log_or_reg = false">立即注册账号</el-link>
							</div>
						</el-checkbox-group>
					</el-form-item>

					<el-alert style="margin: 20px 0px;" title="注 : 点击登录即表明已阅读本站用户协议" type="info" show-icon :closable="false">
					</el-alert>

					<el-alert title="温馨提示: 登录账号时请保护您的账号安全, 切勿泄露信息他人. 防止账号被盗用! 如发现账号被他人登录, 请及时更改密码保障账号安全! 必要时可联系客服冻结账号!" type="warning"
					 style="text-align: left;" show-icon :closable="false">
					</el-alert>

				</el-form>


				<el-form v-else ref="RegFormRef" :model="params" :rules="RegRules" style="margin-top: 20px;">
					<el-form-item prop="username">
						<el-input prefix-icon="iconfont icon-user" placeholder="请输入用户名" onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"></el-input>
					</el-form-item>


					<el-form-item prop="phone">
						<el-input prefix-icon="iconfont icon-dianhua" placeholder="请输入手机号码"></el-input>
					</el-form-item>

					<el-form-item prop="code">
						<el-input prefix-icon="iconfont icon-yanzhengma" placeholder="请输入短信验证码" style="width: 60%;margin-right: 3.4%;"></el-input>
						<el-button style="width: 36.5555%;" type="primary" :disabled="disable" :class="{ codeGeting:isGeting }" @click="GetVerifyCode">{{ getCode }}</el-button>
					</el-form-item>


					<!-- 密码 -->
					<el-form-item prop="password">
						<el-input prefix-icon="iconfont icon-mima" show-password placeholder="请输入密码"></el-input>
					</el-form-item>

					<el-form-item prop="changpass">
						<el-input prefix-icon="iconfont icon-mima" show-password placeholder="请再次输入确认密码"></el-input>
					</el-form-item>

					<!-- 按钮 -->
					<el-form-item class="btns">
						<el-button type="primary" style="width: 100%;" @click="submit">立即注册</el-button>
					</el-form-item>

					<el-form-item class="btns">
						<el-checkbox-group>

							<div style="width: 40%; float: left;">
								<el-switch active-color="#13ce66" active-text="我已阅读用户协议" inactive-color="#ff4949">
								</el-switch>
							</div>

							<div style="width: 60%;float: right;text-align: right;">

								<el-link type="primary" @click="log_or_reg = true">已有账号返回登录</el-link>
							</div>
						</el-checkbox-group>
					</el-form-item>

				</el-form>


			</el-dialog>
			<!-- 登录界面 -->

			<!-- title -->
			<div class="title">
				<div class="title-text">
					专业查Shopee蓝海词
				</div>
			</div>

			<!-- 搜索框 -->
			<div class="search">

				<div class="select-wrap">
					<img src="https://dev-data.geekerp.com/static/img/icon/shopee.svg" class="logo">
					<span class="text" slot="reference">台湾</span>
					<span role="img" aria-label="caret-down" class="anticon anticon-caret-down icon">
						<svg focusable="false" class="" data-icon="caret-down" width="1em" height="1em" fill="currentColor" aria-hidden="true"
						 viewBox="0 0 1024 1024">
							<path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
						</svg>
					</span>
				</div>

				<div class="search-input-wrap">
					<input type="text" class="search-input" placeholder="输入关键词搜索商机">
				</div>

				<div class="search-btn">
					搜索
				</div>


			</div>
		</div>

		<div class="content">

			<!-- 按分类查询 -->
			<div class="content-item">
				<!-- 标题 -->
				<div class="title">
					按分类查询
				</div>
				<!-- 列表 -->
				<div class="category-list-wrap">
					<div v-for="item in category" class="category-item">
						<img class="left-icon" :src="item.image">
						<div class="name">{{ item.c_name }}</div>
					</div>
				</div>

			</div>

			<!-- 走马灯 -->

			<div class="carousel-warp">
				<el-carousel height="120px" direction="horizontal" :autoplay="true" :interval="5000">
					<el-carousel-item v-for="item in 3" :key="item">
						<el-image fit="fill" style='width: 100%;' src="https://dev-data.geekerp.com/assets/1.2c47be93.png"></el-image>
					</el-carousel-item>
				</el-carousel>
			</div>


		</div>

		<div class="foot">
			<div class="foot-content">
				<div class="group-item">
					<div class="title"> 极鲸云 </div>
					<div class="desc">
						智浪数据是雅智优科技旗下的新一代智能选品与数据分析产品。帮助您精准找到“需求大，竞争小”的优质蓝海商品词。结合全方位可视化数据与趋势图表，帮您快速判断市场机遇，掌握市场先机
					</div>
					<div class="link-wrap">
						<a class="link-item" href="https://docs.qq.com/doc/DWmV5V0x1V1NUblRO" target="_blank">关于我们</a>
						<a class="link-item" href="https://docs.qq.com/doc/DWlVlRUpJUkdyTmVs" target="_blank">用户协议</a>
						<a class="link-item" href="https://docs.qq.com/doc/DWnJxYWFpUWx5Zm9j" target="_blank">免责声明</a>
					</div>
					
				</div>
				<div class="group-item">
					<div class="title"> 联系我们 </div>

				</div>
				<div class="group-item">
					<div class="title"> 友情链接 </div>
				</div>
			</div>
			<div class="foot-copyright">
				<span class="title">Copyright © vxiaw.com</span>
				<span class="title">2022 广州雅智优科技有限公司</span>
				<a class="title" href="">粤ICP备2020085523号</a>
			</div>
		</div>
	</div>
</template>

<script>
	import HTTP from '@/request/api'

	export default {
		data() {
			return {
				is_login: false,
				login_dialog: false,
				log_or_reg: true,
				// 登录
				loginForm: {
					username: '',
					password: ''
				},
				loginRules: {
					username: [{
						required: true,
						message: "请输入用户账号",
						trigger: 'blur'
					}, {
						min: 3,
						max: 50,
						message: "用户名长度在 3-50个字符",
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: "请输入用户密码",
						trigger: 'blur'
					}, {
						min: 3,
						max: 20,
						message: "密码长度在 3 - 20 个字符",
						trigger: 'blur'
					}]
				},
				userinfo: {
					username: '',
					token: ''
				},
				category: []
			}
		},
		methods: {
			login: function() {
				this.$refs.loginFormRef.validate(async valid => {
					if (!valid) return;
					HTTP.auth(this.loginForm).then(res => {
						this.is_login = true
						this.userinfo = res.data
						this.login_dialog = false
						this.$message.success('登录成功')
						window.sessionStorage.setItem('token', res.data.token)
						window.sessionStorage.setItem('username', res.data.username)

						// this.$router.push('/user')
					})
				})
			},
			checkLogin: function() {
				this.userinfo.username = window.sessionStorage.getItem('username')
				console.log(this.userinfo.username)
				if (this.userinfo.username != null) {
					this.is_login = true
				}
			},
			getCategory: function() {
				HTTP.categorylist().then(res => {
					this.category = res.data
				})
			},
			logout: function() {
				this.userinfo = {}
				window.sessionStorage.clear()
				this.is_login = false
				this.$message.success("您已退出登录")
			},
			open: function(method) {
				this.login_dialog = true
				if (method == 'login')
					this.log_or_reg = true
				else
					this.log_or_reg = false
			}
		},
		mounted() {
			this.checkLogin()
			this.getCategory()
		}
	}
</script>

<style scoped>
	body {
		margin: 0;
		color: #000000d9;
		font-size: 14px;
		font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
		font-variant: tabular-nums;
		line-height: 1.5715;
		background-color: #fff;
		font-feature-settings: "tnum";
	}

	.data-page {}

	.data-page .header-wrap {
		color: #fff;
		height: 500px;
		background: url(https://dev-data.geekerp.com/static/img/header-bg.png) no-repeat;
		background-size: cover;
		background-position: center center;
	}

	.header-wrap .nav {
		/* background-color: #475669; */
		/* border-bottom: 1px #DCDCDC solid; */
		height: 80px;
		width: 100%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.header-wrap .nav .nav-bar {
		width: 80%;
		min-width: 1300px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.header-wrap .nav .nav-bar .logo {
		height: 100%;
		width: 200px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.header-wrap .nav .nav-bar .logo span {
		padding-left: 5px;
	}

	.header-wrap .nav .nav-bar .logo a {
		width: 130px;
	}


	.header-wrap .title {
		position: relative;
		text-align: center;
	}

	.header-wrap .title .title-text {
		padding-top: 70px;
		font-size: 48px;
		text-shadow: 0 5px 5px rgba(0, 0, 0, .1);
	}

	.header-wrap .search {
		position: relative;
		text-align: center;
		width: 800px;
		display: flex;
		align-items: center;
		margin: 0 auto;
		margin: 70px auto 0;
		border-radius: 5px;
		background: #fff;
		cursor: pointer;
	}

	.header-wrap .search .select-wrap {
		position: relative;
		display: flex;
		align-items: center;
		color: #333;
		font-size: 18px;
		padding: 15px 20px;
	}

	.header-wrap .search .select-wrap .logo {
		width: 24px;
		padding-right: 10px;
		align-items: center;
	}

	.header-wrap .search .search-input-wrap {
		position: relative;
		flex: 1;

	}

	.header-wrap .search .search-input-wrap .search-input {
		border: none;
		color: #333;
		outline: none;
		padding: 15px;
		font-size: 18px;
		width: 100%;
		box-sizing: border-box;
	}

	.header-wrap .search .search-btn {
		display: flex;
		align-items: center;
		background: #E6A23C;
		text-align: center;
		color: #fff;
		border: none;
		height: 56.6px;
		padding: 0 40px;
		font-size: 18px;
		cursor: pointer;
		border-radius: 0 5px 5px 0;
	}


	.anticon {
		padding-left: 10px;
		display: inline-block;
		color: inherit;
		font-style: normal;
		line-height: 0;
		text-align: center;
		text-transform: none;
		vertical-align: -.125em;
		text-rendering: optimizelegibility;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.content {
		margin: 0 auto;
		width: 80%;
		min-width: 1300px;
	}

	.content .content-item {
		margin: 50px 0px;
	}

	.content .content-item .title {
		font-size: 28px;
		font-weight: 700;
		color: #2b2c2f;
		margin-bottom: 28px;
		position: relative;
	}

	.content .content-item .category-list-wrap {
		overflow: hidden;
	}

	.content .content-item .category-list-wrap .category-item {
		display: flex;
		width: 20%;
		float: left;
		align-items: center;
		padding: 15px 20px;
		box-sizing: border-box;
		border-radius: 10px;
		margin-bottom: 10px;
		cursor: pointer;
	}

	.content .content-item .category-list-wrap .category-item:hover {
		background: rgb(245, 245, 245);
	}

	.content .content-item .category-list-wrap .category-item .left-icon {
		height: 50px;
		margin-right: 15px;
	}

	.content .content-item .category-list-wrap .category-item .name {
		font-size: 16px;
		font-weight: 700;
	}

	.content .carousel-warp {
		/* background-color: #475669; */
		margin-bottom: 50px;
	}

	.foot {
		margin-top: 30px;
		background-color: #141a25;
		color: #fff;
	}

	.foot .foot-content {
		width: 80%;
		min-width: 1300px;
		margin: 0 auto;
		display: flex;
		padding: 48px 0;
	}

	.foot .foot-content .group-item {
		flex: 1;
	}

	.foot .foot-content .group-item .title {
		font-weight: 700;
		font-size: 18px;
		margin-bottom: 28px;
	}
	.foot .foot-content .group-item .link-item{
		color: #fff;
		opacity: .6;
		margin: 0 32px 24px 0;
		text-decoration: none;
	}
	.foot .foot-content .group-item .link-item:hover{
		opacity: 1;
	}
	.foot .foot-content .group-item .desc{
		margin: 28px 60px 28px 0;
		opacity: .6;
	}
	.foot .foot-copyright{
		width: 80%;
		min-width: 1300px;
		margin: 0 auto;
		border-top: 1px solid rgba(255,255,255,.1);
		padding: 20px;
		text-align: center;
	}
	.foot .foot-copyright .title{
		color: #ffffff4d;
		padding-right: 20px;
	}
</style>
