<template>

	<el-row style="min-width: 1200px;">

		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>公告管理</span>
			</div>

			<div>

				<el-alert :closable="false" style="margin-bottom: 10px;" title="管理所有公告列表" type="info" show-icon>
				</el-alert>

				<div>
					<el-input v-model="params.title" clearable style="width: 250px; margin-right: 10px;" size="small"
					 placeholder="请输入标题搜索" prefix-icon="el-icon-search"></el-input>
					<el-button size="small" icon="el-icon-search" @click="GetNotice">搜索一下</el-button>
					<el-button size="small" type="primary" icon="el-icon-circle-plus-outline" @click="dialogFormVisible=true">添加公告</el-button>
				</div>


				<div>

					<el-table :data="data.data" height="600">
						<template slot="empty">
							<el-empty :image-size="200" description="暂未查询到发布的任务数据哦!"></el-empty>
						</template>
						<el-table-column type="index"></el-table-column>
						<el-table-column prop="title" label="标题" align="center"></el-table-column>
						<el-table-column prop="view" label="展示" align="center">
						</el-table-column>
						<el-table-column prop="create_time" label="创建日期" align="center">
						</el-table-column>

						<el-table-column prop="update_time" label="更新日期" align="center">
						</el-table-column>

						<el-table-column label="操作" width="200" align="center">

							<template slot="header" slot-scope="scope">
								<el-button type="primary" icon="el-icon-refresh" size="mini" @click="GetNotice">刷新</el-button>
							</template>

							<template slot-scope="scope">
								<el-button size="mini" @click="EditViewInfo(scope.row)">编辑</el-button>
								
								<el-popconfirm title="确定要删除选中的数据吗？" @confirm="DelNotice(scope.row.id)" style="margin: 0px 15px;">
									<el-button slot="reference" type="danger" size="mini">删除</el-button>
								</el-popconfirm>
								
							</template>
						</el-table-column>

					</el-table>


					<el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="this.params.page"
					 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next" :total="data.total" style="margin-top:15px; margin-bottom: 15px; position: relative;">
					</el-pagination>
				</div>


				<el-dialog custom-class="dialog-box-card" title="添加公告" :visible.sync="dialogFormVisible" append-to-body style="min-width: 910px; max-width: 910px; margin: 0 auto;">
					<div style="margin: 15px 0px;">
						<el-input style="width: 100%;" v-model="add_params.title" placeholder="请输入公告标题"></el-input>
					</div>
					<div class="clearfix">
						<quill-editor style="height: 300px;" v-model="add_params.content" />
					</div>
					
					<div style="margin: 100px 0px;">
						<el-button clearable icon="el-icon-thumb" size="small" type="primary" @click="AddNotice">发布公告</el-button>
					</div>

				</el-dialog>


				<el-dialog title="编辑公告" custom-class="dialog-box-card" :visible.sync="editVisible" append-to-body style="min-width: 510px; max-width: 910px; margin: 0 auto;">
					<div style="margin: 15px 0px;">
						<el-input style="width: 100%;" v-model="edit_params.title" placeholder="请输入公告标题"></el-input>
					</div>
					<div class="clearfix">
						<quill-editor style="height: 300px;" v-model="edit_params.content" />
					</div>
					
					<div style="margin: 100px 0px;">
						<el-button clearable icon="el-icon-thumb" size="small" type="primary" @click="EditNotice">更新公告</el-button>
					</div>
				</el-dialog>

			</div>
		</el-card>


	</el-row>

</template>

<script>
	import HTTP from '@/request/admin/api'

	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import {
		quillEditor
	} from 'vue-quill-editor'

	export default {

		components: {
			quillEditor
		},

		data() {
			return {
				data: [],
				params: {
					page: 1,
					limit: 10,
					title: ''
				},
				edit_params: {
					id: '',
					title:'',
					content: ''
				},
				add_params: {
					title:'',
					content: ''
				},
				editVisible: false,
				dialogFormVisible: false,
			}
		},
		mounted() {
			this.GetNotice()
		},

		methods: {

			// 获取公告列表
			GetNotice: function() {
				HTTP.getNoticeList(this.params).then(res => {
					this.data = res
				})
			},

			// 编辑公告信息
			EditViewInfo: function(row) {
				this.edit_params.id = row.id
				this.edit_params.title = row.title
				this.edit_params.content = row.content
				this.editVisible = true
			},

			EditNotice: function() {
				HTTP.editNotice(this.edit_params).then(res => {
					this.$message.success(res.message)
					this.editVisible = false
					this.GetNotice()
				})
			},
			
			// 删除公告
			DelNotice: function(id){
				HTTP.delNotice({id: id}).then(res=>{
					this.$message.success(res.message)
					this.GetNotice()
				})
			},

			// 添加公告
			AddNotice: function() {
				HTTP.AddNotice(this.add_params).then(res => {
					this.$message.success(res.message)
					this.dialogFormVisible = false
					this.GetNotice()
				})
			},

			handleSizeChange(value) {
				this.params.limit = value
				this.GetNotice()
			},
			handleCurrentChange(value) {
				this.params.page = value
				this.GetNotice()
			},
		}
	}
</script>

<style scoped>
	.el-row {
		text-align: left;
	}

	.user-box {
		background: #fff;
		height: 100px;
		padding: 20px;
		margin-bottom: 20px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
	}

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.box-card {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		color: #000000d9;
		font-size: 14px;
		font-variant: tabular-nums;
		line-height: 1.5715;
		list-style: none;
		font-feature-settings: "tnum";
		position: relative;
		background: #fff;
		border-radius: 10px;
	}

	/deep/.dialog-box-card {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		color: #000000d9;
		font-size: 14px;
		font-variant: tabular-nums;
		line-height: 1.5715;
		list-style: none;
		font-feature-settings: "tnum";
		position: relative;
		background: #fff;
		border-radius: 10px;
		min-width: 910px;
		max-width: 910px;
		margin: 0 auto;
		height: 620px;
	}

	/deep/ .el-dialog__header {
		padding: 16px 24px;
		color: #000000d9;
		border-bottom: 1px solid #f0f0f0;
	}
</style>
