const current = 'prod'
const profiles = {
	'dev': {
		'online': false,
		'baseURL': 'http://127.0.0.1:8866/v1/api/'
	},
	'prod': {
		'online': true,
		'baseURL': 'https://api.2b82.com/v1/api/'
	}
}
const ENV = profiles[current]
module.exports = ENV