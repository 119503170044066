<template>
 
 	<el-row style="min-width: 1200px;">
 
 		<el-card class="box-card">
 			<div slot="header" class="clearfix">
 				<span>账变记录</span>
 			</div>
 
 			<div>
 
 				<el-alert :closable="false" style="margin-bottom: 10px;" title="账单信息充值/消费记录面板"
 				 type="info" show-icon>
 				</el-alert>
 
 
 				<div>
							
					<el-tabs v-model="activeName">
						<el-tab-pane label="充值记录" name="recharge">
							<el-table :data="data.data" height="auto">
								<template slot="empty">
									<el-empty :image-size="200" description="暂未查询到充值记录数据哦!"></el-empty>
								</template>
								<el-table-column type="index" label="ID"></el-table-column>
								<el-table-column prop="out_trade_no" label="订单号" align="center">
								</el-table-column>
								<el-table-column prop="pay_amount" label="充值金额" align="center">
								</el-table-column>
							
								<el-table-column prop="pay_type" label="支付方式" align="center">
									<template slot-scope="scope">
										<el-tag size="mini" type="success">支付宝</el-tag>
									</template>
								</el-table-column>
								
								<el-table-column prop="pay_state" label="订单状态" align="center">
									<template slot-scope="scope">
										<el-tag size="mini" v-if="scope.row.pay_state" type="success">支付成功</el-tag>
										<el-tag size="mini" v-else type="danger">等待支付</el-tag>
									</template>
								</el-table-column>
							
								<el-table-column prop="create_time" label="订单时间" width="150" align="center">
								</el-table-column>
								<el-table-column prop="update_time" label="更新时间" width="150" align="center">
								</el-table-column>
					
							</el-table>
							
							<el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="this.params.page"
							 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next" :total="data.total" style="margin-top:15px; margin-bottom: 15px; position: relative;">
							</el-pagination>
						</el-tab-pane>
						
					
						<el-tab-pane label="账变记录" name="order">
							<el-table :data="record_data.data" height="auto">
								<template slot="empty">
									<el-empty :image-size="200" description="暂未查询到发布的任务数据哦!"></el-empty>
								</template>
								<el-table-column type="index" label="ID"></el-table-column>
								<el-table-column prop="original" label="原有金额" align="center">
								</el-table-column>
								<el-table-column prop="change" label="变更金额" align="center">
								</el-table-column>
								<el-table-column prop="new_balance" label="现有金额" align="center">
								</el-table-column>
								<el-table-column prop="desc" label="变更描述" align="center">
									<template slot-scope="scope">
										<el-tag size="mini" type="info">{{ scope.row.desc }}</el-tag>
									</template>
								</el-table-column>
								
								<el-table-column label="记录时间" prop="create_time" width="200" align="center">
									<template slot="header" slot-scope="scope">
										<el-button type="primary" icon="el-icon-refresh" size="mini" @click="GetRecordList">刷新</el-button>
									</template>
									<el-table-column prop="create_time" label="记录时间" width="200" align="center">
										
									</el-table-column>
								</el-table-column>
								
							</el-table>
							
							<el-pagination small @size-change="record_handleSizeChange" @current-change="record_handleCurrentChange" :current-page="this.record_params.page"
							 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next" :total="record_data.total" style="margin-top:15px; margin-bottom: 15px; position: relative;">
							</el-pagination>
						</el-tab-pane>
					</el-tabs>
					
					
					
					
					
 					
 				</div>
 
 
 			</div>
 		</el-card>
 
 
 	</el-row>
 
 </template>
 
 <script>
 	import HTTP from '@/request/api'
 
 	export default {
 		data() {
 			return {
 				data: [],
 				record_data: [],
 				params: {
 					page: 1,
 					limit: 10,
 				},
 				record_params: {
 					page: 1,
 					limit: 10,
 				},
 				
 				dialogFormVisible: false,
 				activeName: 'recharge'
 			}
 		},
 		mounted() {
 			this.GetRechargeList()
 			this.GetRecordList()
 		},
 		methods: {
 			
 			GetRechargeList: function(){
 				HTTP.userRechargeList(this.params).then(res=>{
 					this.data = res
 				})
 			},
 			GetRecordList: function(){
 				HTTP.userRecord(this.record_params).then(res=>{
 					this.record_data = res
 				})
 			},
 	
 	
 			handleSizeChange(value) {
 				this.params.limit = value
 				this.GetRechargeList()
 			},
 			handleCurrentChange(value) {
 				this.params.page = value
 				this.GetRechargeList()
 			},
 			
 			record_handleSizeChange(value) {
 				this.record_params.limit = value
 				this.GetRecordList()
 			},
 			record_handleCurrentChange(value) {
 				this.record_params.page = value
 				this.GetRecordList()
 			},
 		}
 	}
 </script>
 
 <style scoped>
 	.el-row {
 		text-align: left;
 	}
 
 	.user-box {
 		background: #fff;
 		height: 100px;
 		padding: 20px;
 		margin-bottom: 20px;
 		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
 	}
 
 	.el-row {
 		margin-bottom: 20px;
 
 		&:last-child {
 			margin-bottom: 0;
 		}
 	}
 
 	.box-card {
 		box-sizing: border-box;
 		margin: 0;
 		padding: 0;
 		color: #000000d9;
 		font-size: 14px;
 		font-variant: tabular-nums;
 		line-height: 1.5715;
 		list-style: none;
 		font-feature-settings: "tnum";
 		position: relative;
 		background: #fff;
 		border-radius: 10px;
 	}
 
 	/deep/.dialog-box-card {
 		box-sizing: border-box;
 		margin: 0;
 		padding: 0;
 		color: #000000d9;
 		font-size: 14px;
 		font-variant: tabular-nums;
 		line-height: 1.5715;
 		list-style: none;
 		font-feature-settings: "tnum";
 		position: relative;
 		background: #fff;
 		border-radius: 10px;
 		min-width: 520px;
 		max-width: 520px;
 		margin: 0 auto;
 	}
 
 	/deep/ .el-dialog__header {
 		padding: 16px 24px;
 		color: #000000d9;
 		border-bottom: 1px solid #f0f0f0;
 	}
 </style>
 