import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Reg from '../views/Register.vue'

import Home from '../views/Home/Index.vue'
import Dashboard from '../views/Home/Dashboard.vue'
import Pay from '../views/Home/Pay.vue'
import RechargeOrder from '../views/Home/RechargeOrder.vue'
import LoginLog from '../views/Home/LoginLog.vue'

import Video from '../views/Home/video.vue'
import Photo from '../views/Home/Photo.vue'
import Apple from '../views/Home/apple.vue'
import Peiyin from '../views/Home/Peiyin.vue'
// import ImageEditor from '../views/image-edit.vue'


import ControlPanel from '../views/Admin/ControlPanel.vue'
import AdminLogin from '../views/Admin/Login.vue'
import AdminHome from '../views/Admin/Index.vue'
import AdminDashboard from '../views/Admin/Dashboard.vue'
import AdminUserList from '../views/Admin/UserList.vue'
import AdminRechargeList from '../views/Admin/RechargeOrder.vue'
import AdminLoginLog from '../views/Admin/LoginLog.vue'
import AdminVideo from '../views/Admin/video.vue'
import Index from '../views/Index.vue'
import AdminPackage from '../views/Admin/package.vue'
import AdminNotice from '../views/Admin/notice.vue'
import AdminPackageRecord from '../views/Admin/packageRecord.vue'
import AdminPeiyin from '../views/Admin/peiyin.vue'


Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Index',
		component: Index,
		meta: {
			title: "微虾数据管家_数据分析_蓝海挖掘大数据"
		},
		
		redirect: '/login'
	},
	{
		path: '/admin/login',
		name: 'AdminLogin',
		component: AdminLogin,
		meta: {
			title: '管理登录'
		}
	},
	{
		path: '/admin',
		name: 'Admin',
		component: AdminHome,
		redirect: '/admin/dashboard',
		children: [{
				path: '/admin/dashboard',
				component: AdminDashboard,
				meta: {
					title: '后台管理工作仪表盘'
				}
			},
			{
				path: '/admin/userlist',
				component: AdminUserList,
				meta: {
					title: '用户管理'
				}
			},
			{
				path: '/admin/control',
				component: ControlPanel,
				meta: {
					title: '控制面板'
				}
			},
			{
				path: '/admin/video',
				component: AdminVideo,
				meta: {
					title: '视频翻译管理'
				}
			},
			{
				path: '/admin/packageRecord',
				component: AdminPackageRecord,
				meta: {
					title: '套餐购买记录'
				}
			},
            {
                path: '/admin/peiyin',
                component: AdminPeiyin,
                meta: {
                    title: '配音记录管理'
                }
            },
			{
				path: '/admin/package',
				component: AdminPackage,
				meta: {
					title: '套餐列表管理'
				}
			},
			{
				path: '/admin/notice',
				component: AdminNotice,
				meta: {
					title: '公告列表管理'
				}
			},
			{
				path: '/admin/recharge',
				component: AdminRechargeList,
				meta: {
					title: '充值账变管理'
				}
			},
			{
				path: '/admin/loglog',
				component: AdminLoginLog,
				meta: {
					title: '登录日志管理'
				}
			}
			
		],
		meta: {
			title: '管理中心'
		}
	},
	
 //    {
 //        path: '/edit',
 //        name: 'ImageEditor',
 //        component :ImageEditor,
 //        meta: {
 //            title: '图片编辑器'
 //        }
 //    },
	
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			title: '登录'
		}
	},
	{
		path: '/reg',
		name: 'Register',
		component: Reg,
		meta: {
			title: '注册'
		}
	},
	{
		path: '/user',
		name: 'User',
		component: Home,
		redirect: '/user/dashboard',
		children: [{
				path: '/user/dashboard',
				component: Dashboard,
				meta: {
					title: '工作仪表盘'
				}
			},
			{
				path: '/user/video',
				component: Video,
				meta: {
					title: '视频翻译'
				}
			},
            {
                path: '/user/photo',
                component: Photo,
                meta: {
                    title: '图片翻译'
                }
            },{
                path: '/user/peiyin',
                component: Peiyin,
                meta: {
                    title: '文字配音'
                }
            },
			{
				path:'/user/apple',
				component: Apple,
				meta: {
					title: 'AppleID'
				}
			},
			
			{
				path: '/user/pay',
				component: Pay,
				name: 'Pay',
				meta: {
					title: '余额充值中心'
				}
			},
			{
				path: '/user/recharge',
				component: RechargeOrder,
				name: 'RechargeOrder',
				meta: {
					title: '账单信息管理'
				}
			},
			{
				path: '/user/loginlog',
				component: LoginLog,
				name: 'LoginLog',
				meta: {
					title: '登录日志管理'
				}
			},
			
			
			
		],
		meta: {
			title: '会员中心'
		}
	}

]

const router = new VueRouter({
	mode: "history",
	routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title + ' -  云象视频字幕翻译平台'
	}
	next()
})




export default router
