import request from './server.js'

const HTTP = {
    
    // 获取音频列表
    getAiVoicelist(data){
        return request({
            url: 'user/ai/voicelist',
            methos: 'GET',
            params: data
        }, true)
    },
    
    // 获取AI配音套餐列表
    getAiPackage(data){
        return request({
            url: 'user/ai/package',
            method: 'GET',
            params: data
        }, true)
    },
    
    // 获取合成记录
    getAiList(data){
        return request({
            url: 'user/ai/record',
            method: 'GET',
            params:data
        }, true)
    },
    
    // 创建AI翻译
    CreateAiVoice(data){
        return request({
            url: 'user/ai/create',
            method: 'POST',
            data:data
        }, true)
    },
    
    // 购买AI配音套餐
    BuyAiPackage(data){
        return request({
            url: 'user/ai/package',
            method: 'POST',
            data:data
        }, true)
    },
    
    // // 获取本人AI配音套餐
    // getMyAiPackage(){
    //     return request({
    //         url: 'user/ai/info',
    //         method: 'GET'
    //     }, true)
    // },
    
	//APPLE
	getApple(){
		return request({
			url: 'user/apple',
			method: 'GET',
		}, true)
	},
	// 获取公告
	getNoticeList(data){
		return request({
			url: 'user/notice',
			method: 'GET',
			params: data
		}, true)
	},
	getNoticeContent(data){
		return request({
			url: 'user/notice',
			method: 'POST',
			data: data
		}, true)
	},
	// 获取抖音视频信息
	getDouyin(data){
		return request({
			url: 'user/douyin',
			method: 'POST',
			data: data
		}, true)
	},
	// 获取video可用次数
	getVideoCount(){
		return request({
			url: 'user/video/count',
			method: 'GET',
		}, true)
	},
    // 获取photo可用次数
    getPhotoCount(){
        return request({
            url: 'user/photo/count',
            method: 'GET',
        }, true)
    },
    // 获取图片套餐列表
    getPackagePhoto(){
      return request({
          url: 'user/package/photo',
          method: 'GET'
      }, true)  
    },
    // 购买图片套餐
    buyPackagePhoto(data){
      return request({
            url: 'user/package/photo',
            method: 'POST',
            data: data
      }, true)  
    },
	// 获取视频套餐列表
	getPackageVideo(){
		return request({
			url: 'user/package/video',
			method: 'GET',
		}, true)
	},
	// 购买视频翻译
	buyPackageVideo(data){
		return request({
			url: 'user/package/video',
			method: 'POST',
			data: data
		}, true)
	},
	// 视频翻译购买记录
	getPackageVideoRecord(data){
		return request({
			url: 'user/package/record',
			method: 'GET',
			params: data
		}, true)
	},
	
	
	// 登录认证
	auth(data){
		return request({
			url: 'user/login',
			method: 'POST',
			data
		})
	},
	// 获取客服列表
	kefu(){
		return request({
			url: 'user/kefu',
			method: 'GET'
		})
	},
	// 注册账号
	register(data){
		return request({
			url: 'user/register',
			method: 'POST',
			data
		})
	},
	// 发送信息
	sendsms(data){
		return request({
			url: 'user/register',
			method: 'GET',
			params: data
		})
	},
	// 获取用户信息
	userinfo(){
		return request({
			url: 'user/info',
			method: 'GET'
		}, true)
	},
	carousel(){
		return request({
			url: 'user/carousel',
			method: 'GET'
		}, true)
	},
	// 获取登录日志
	userLoginLog(data){
		return request({
			url: 'user/log/list',
			method: 'GET',
			params: data
		}, true)
	},

	// 支付
	userPay(data){
		return request({
			url: 'user/pay',
			method: 'POST',
			data
		}, true)
	},
	// 检测订单状态
	userCheckOrder(data){
		return request({
			url: 'user/pay',
			method: 'GET',
			params: data
		}, true)
	},
	// 获取充值订单列表
	userRechargeList(data){
		return request({
			url: 'user/pay/record',
			method: 'GET',
			params: data
		}, true)
	},
	// 获取账变列表
	userRecord(data){
		return request({
			url: 'user/change/record',
			method: 'GET',
			params: data
		}, true)
	},
	// 创建视频翻译任务
	createVideoTrans(data){
		return request({
			url: 'user/create/video',
			method: 'POST',
			data
		}, true)
	},
    // 创建图片翻译任务
    createPhotoTrans(data){
        return request({
            url: 'user/create/photo',
            method: 'POST',
            data
        }, true)
    },
    updataPhotoTrans(data){
        return request({
            url: 'user/create/photo',
            method: 'PUT',
            data: data,
        }, true)
    },
	// 视频翻译列表
	userVideoList(data){
		return request({
			url: 'user/video/list',
			method: 'GET',
			params: data
		}, true)
	},
    // 图片翻译列表
    userPhotoList(data){
        return request({
            url: 'user/photo/list',
            method: 'GET',
            params: data
        }, true)
    },
	// 获取渲染视频下载
	userVideoDown(data){
		return request({
			url: 'user/video/down',
			method: 'POST',
			data
		}, true)
	},
	// 获取视频编辑
	userVideoEdit(data){
		return request({
			url: 'user/video/edit',
			method: 'POST',
			data
		}, true)
	},
	// 刷新视频状态
	userVideoRefresh(data){
		return request({
			url: 'user/video/refresh',
			method: 'POST',
			data
		}, true)
	}
}


module.exports = HTTP;
