<template>

	<el-row style="min-width: 1200px;" v-loading.fullscreen.lock="loading" element-loading-text="正在处理中, 请耐心等待..."
	 element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">

		<el-card class="box-card" style="min-height: 800px; height: 800px;">
			<div slot="header" class="clearfix">
				<span>Apple ID账号分享信息</span>
			</div>

			<div>

				<el-alert :closable="false" style="margin-bottom: 10px;" title="账号密码24小时自动更新，刷新网页即可获取最新密码, 以下账号为网友分享.注意千万不要在【设置】里面登录，也不要在【icloud】里面登录，以免锁机，切记！！"
				 type="info" show-icon>
				</el-alert>


				<el-row :gutter="15" style="margin-top: 20px;">

					<el-col span="6" v-for="item in data" style="margin-bottom: 15px;">
						<el-card class="box-card">

							<el-form label-width="50px" class="demo-dynamic" size="small">

								<el-divider content-position="left">分享账号：AppStore</el-divider>

								<el-form-item label="账号">
									<div style="display: flex; justify-content: space-between;">
										<el-input v-model="item.username" style="margin-right: 15px;"></el-input>
										<el-button @click="copyToClipboard(item.username)">复制</el-button>
									</div>
								</el-form-item>

								<el-form-item label="密码">
									<div style="display: flex; justify-content: space-between;">
										<el-input v-model="item.password" style="margin-right: 15px;"></el-input>
										<el-button @click="copyToClipboard(item.password)">复制</el-button>
									</div>
								</el-form-item>

								<el-form-item label="状态">
									<el-tag type="info">{{ item.state }}</el-tag>
								</el-form-item>

								<el-form-item label="更新">
									<span>{{ item.time }}</span>
								</el-form-item>

							</el-form>

						</el-card>
					</el-col>


				</el-row>


			</div>
		</el-card>


	</el-row>

</template>

<script>
	import HTTP from '@/request/api'

	export default {

		data() {
			return {
				loading: false,
				data: []
			}
		},
		mounted() {
			this.GetApple()
		},
		methods: {
			GetApple: function() {
				this.loading = true
				HTTP.getApple().then(res => {
					this.loading = false
					this.data = res.data
				}).finally(err => {
					this.loading = false
				})
			},

			copyToClipboard: function(s) {
				if (window.clipboardData) {
					window.clipboardData.setData('text', s);
				} else {
					(function(s) {
						document.oncopy = function(e) {
							e.clipboardData.setData('text', s);
							e.preventDefault();
							document.oncopy = null;
						}
					})(s);
					document.execCommand('Copy');
				}
				
				this.$message.success('已经复制到剪辑版，右键黏贴即可黏贴到需要的位置！')
			}

		}
	}
</script>

<style scoped>
	.el-row {
		text-align: left;
	}

	.user-box {
		background: #fff;
		height: 100px;
		padding: 20px;
		margin-bottom: 20px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
	}

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.box-card {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		color: #000000d9;
		font-size: 14px;
		font-variant: tabular-nums;
		line-height: 1.5715;
		list-style: none;
		font-feature-settings: "tnum";
		position: relative;
		background: #fff;
		border-radius: 10px;
	}

	/deep/.dialog-box-card {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		color: #000000d9;
		font-size: 14px;
		font-variant: tabular-nums;
		line-height: 1.5715;
		list-style: none;
		font-feature-settings: "tnum";
		position: relative;
		background: #fff;
		border-radius: 10px;
		min-width: 520px;
		max-width: 520px;
		margin: 0 auto;
	}

	/deep/ .el-dialog__header {
		padding: 16px 24px;
		color: #000000d9;
		border-bottom: 1px solid #f0f0f0;
	}
</style>
