<template>

    <el-row :gutter="15" style="min-width: 1200px;" v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="服务努力加载中"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">


        <el-col :span="6">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <div style="display: flex; justify-content: space-between;">
                        <span>图片翻译中心</span>
                        <span>图片翻译剩余额度：{{ userinfo }} 次</span>
                    </div>
                </div>

                <div>

                    <el-alert :closable="false" style="margin-bottom: 10px;" title="支持图片链接/本地上传等方式,每次扣除一积分" type="info"
                        show-icon>
                    </el-alert>



                    <div class="centent">
                        <div class="1box">
                            <el-form label-width="80px" label-position="left">

                                <el-tabs v-model="activeName">
                                    <el-tab-pane label="图片地址" name="first">
                                        <el-input v-model="params.address" type="textarea" :rows="8" placeholder="输入图片链接,或选择本地图片上传方式.">
                                        </el-input>
                                    </el-tab-pane>
                                    <el-tab-pane label="本地图片" name="second">
                                        <el-upload class="123" :limit='1' name="file-stream" :before-upload="uploadSuccess"
                                            :on-remove="handleRemove" :on-exceed="uploadExceed" :on-success="uploadPhotoSuccess"
                                            :on-error="uploadVideoError" drag action="https://api2.tosoiot.com/upload"
                                            multiple>
                                            <i class="el-icon-upload"></i>
                                            <div class="el-upload__text">将图片文件拖到此处，或<em>点击上传</em></div>
                                            <div class="el-upload__tip" slot="tip">只能上传图片文件，格式JPG\PNG\BMP 且不超过200MB大小</div>
                                        </el-upload>
                                    </el-tab-pane>
                                </el-tabs>


                                <el-form-item label="原图语言:" style="margin-top: 25px;">
                                    <el-select v-model="params.sourceLanguage" placeholder="请选择" style="width: 100%;">
                                        <el-option v-for="item in sourceLanguage" :key="item.value" :label="item.label"
                                            :value="item.value">
                                            <span style="float: left">{{ item.label }}</span>
                                            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>

                                <el-form-item label="目标语言:">
                                    <el-select v-model="params.targetLanguage" placeholder="请选择" style="width: 100%;">
                                        <el-option v-for="item in targetLanguage" :key="item.value" :label="item.label"
                                            :value="item.value">
                                            <span style="float: left">{{ item.label }}</span>
                                            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>


                                <el-form-item label="">
                                    <el-button clearable icon="el-icon-thumb" size="small" type="infor" @click="CreatePhotoTrans">创建翻译</el-button>
                                </el-form-item>

                            </el-form>
                        </div>

                    </div>


                </div>
            </el-card>
        </el-col>

        <el-col :span="18">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <div style="display: flex; justify-content: space-between;">
                        <span><i class="el-icon-picture"></i> 图片翻译历史</span>
                    </div>
                </div>

                <div>

                    <el-alert :closable="false" style="margin-bottom: 10px;" title="可点击图片进行图片精修优化." type="info"
                        show-icon>
                    </el-alert>

                    <el-row :gutter="15">

                        <el-col :span="4" v-for="item in data.data" style="margin-bottom: 15px;">
                            <el-card :body-style="{ padding: '0px' }" style="min-height: 210px;">
                                <div style="width: 100%; position: relative;">
                                    <el-image class="img-list" style="max-height: 180px; min-height: 180px; height: 180px;"
                                        :src="item.SslUrl" @click="ViewPhoto(item)"></el-image>
                                </div>


                                <div style="height: 40px;">
                                    <div style="display: flex; justify-items: center; justify-content: space-between; padding:10px 15px;color: #ccc;">
                                        <span style="font-size: 8px; ">{{Language[item.sourceLanguage]}}</span>
                                        <!-- <el-tag size="mini" effect="plain">{{Language[item.sourceLanguage]}}</el-tag> -->
                                        <el-tag size="mini" effect="plain">{{Language[item.targetLanguage]}}</el-tag>
                                    </div>

                                </div>
                            </el-card>
                        </el-col>


                    </el-row>


                    <div style="position: absolute; bottom: 0; padding: 15px 0;">

                        <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="this.photo_params.page" :page-sizes="[12, 24, 50, 100]" :page-size="12"
                            layout="total, prev, pager, next" :total="data.total" style="margin-top:15px; margin-bottom: 15px; position: relative;">
                        </el-pagination>
                    </div>


                    <!-- 弹出图片查看编辑 -->
                    <el-drawer title="图片查看" :visible.sync="edit" size="90%">

                        <!-- background-color: #2C3E50; -->
                        <div style=" height: 100%; ">
                            <div style="padding: 0 20px; height: 100%;">

                                <el-tabs v-model="activeName_view" type="card" style="height: 100%;">
                                    <el-tab-pane label="AI翻译" name="first">
                                        <div style="display: flex; float: left;">
                                            <div style="height: calc(100vh - 140px); width: auto; float: left;">
                                                <img style="height: 100%;" :src="view_info.SslUrl" alt="">
                                            </div>
                                            <div style="height: calc(100vh - 140px); margin: 0 15px; float: left;">

                                                <el-button type="primary" icon="el-icon-download" @click="downloadIamge(view_info.SslUrl)">下载图片</el-button>
                                                <el-button type="infor" icon="el-icon-s-promotion" @click="copy(view_info.SslUrl)">复制地址</el-button>

                                            </div>
                                        </div>
                                    </el-tab-pane>
                                    <el-tab-pane label="查看原图" name="first1">
                                        <div style="display: flex; float: left;">
                                            <div style="height: calc(100vh - 140px); width: auto; float: left;">
                                                <img style="height: 100%;" :src="view_info.address" alt="">
                                            </div>

                                            <div style="height: calc(100vh - 140px); margin: 0 15px; float: left;">
                                            
                                                <el-button type="primary" icon="el-icon-download" @click="downloadIamge(view_info.address)">下载图片</el-button>
                                                <el-button type="infor" icon="el-icon-s-promotion" @click="copy(view_info.address)">复制地址</el-button>
                                            
                                            </div>

                                        </div>
                                    </el-tab-pane>
                                    <el-tab-pane label="图片精修" name="third">
                                        <div id="iframeBox" style="height: calc(100vh - 140px);">
                                            <iframe v-if="edit" id="xiangji-image-editor" title="图片精修工具" style="width: 100%; height: 100%;"
                                                src="https://www.xiangjifanyi.com/image-editor/#/?logo=hidden"
                                                frameborder="0" security="restricted" @load="iframeOnload(view_info.RequestId)"></iframe>
                                        </div>

                                        <!-- security="restricted" -->


                                    </el-tab-pane>
                                </el-tabs>

                            </div>
                        </div>

                    </el-drawer>


                </div>
            </el-card>
        </el-col>



    </el-row>

</template>

<script>
    import HTTP from '@/request/api'

    export default {
        data() {
            return {
                activeName: 'first',
                activeName_view: 'first',
                dialogFormVisible: false,
                fullscreenLoading: false,
                edit: false,
                edit_url: '',
                userinfo: [],
                sourceLanguage: [{
                    value: 'CHS',
                    label: '简体中文'
                }, {
                    value: 'CHT',
                    label: '中文(繁體)'
                }, {
                    value: 'ENG',
                    label: '英语'
                }, {
                    value: 'JPN',
                    label: '日本语'
                }],
                targetLanguage: [{
                        value: 'AR',
                        label: '阿拉伯语'
                    },
                    {
                        value: 'CSY',
                        label: '捷克语'
                    },
                    {
                        value: 'DEU',
                        label: '德语'
                    },
                    {
                        value: 'ENG',
                        label: '英语'
                    },
                    {
                        value: 'ESP',
                        label: '西班牙语'
                    },
                    {
                        value: 'TL',
                        label: '他加禄语(菲律宾)'
                    },
                    {
                        value: 'FRA',
                        label: '法语'
                    },
                    {
                        value: 'HUN',
                        label: '匈牙利语'
                    },
                    {
                        value: 'ID',
                        label: '印尼语'
                    },
                    {
                        value: 'ITA',
                        label: '意大利语'
                    },
                    {
                        value: 'JPN',
                        label: '日本语'
                    },
                    {
                        value: 'JW',
                        label: '爪哇语'
                    },
                    {
                        value: 'KOR',
                        label: '韩国语'
                    },
                    {
                        value: 'MS',
                        label: '马来语'
                    },
                    {
                        value: 'MY',
                        label: '缅甸语'
                    },
                    {
                        value: 'NLD',
                        label: '荷兰语'
                    },
                    {
                        value: 'PLK',
                        label: '波兰语'
                    },
                    {
                        value: 'PTB',
                        label: '葡萄牙语(巴西)'
                    },
                    {
                        value: 'ROM',
                        label: '罗马尼亚语'
                    },
                    {
                        value: 'RUS',
                        label: '俄语'
                    },
                    {
                        value: 'TH',
                        label: '泰语'
                    },
                    {
                        value: 'TRK',
                        label: '土耳其语'
                    },
                    {
                        value: 'VIN',
                        label: '越南语'
                    },
                    {
                        value: 'CHS',
                        label: '中文(简体)'
                    },
                    {
                        value: 'CHT',
                        label: '中文(繁體)'
                    }
                ],
                params: {
                    address: '',
                    sourceLanguage: '',
                    targetLanguage: ''
                },
                photo_params: {
                    page: 1,
                    limit: 12
                },
                data: [],
                view_info: '',

                Language: {
                    AR: "阿拉伯语",
                    CSY: "捷克语",
                    DEU: "德语",
                    ENG: '英语',
                    ESP: '西班牙语',
                    TL: '他加禄语(菲律宾)',
                    FRA: '法语',
                    HUN: '匈牙利语',
                    ID: '印尼语',
                    ITA: '意大利语',
                    JPN: '日本语',
                    JW: '爪哇语',
                    KOR: '韩国语',
                    MS: '马来语',
                    MY: '缅甸语',
                    NLD: '荷兰语',
                    PLK: '波兰语',
                    PTB: '葡萄牙语(巴西)',
                    ROM: '罗马尼亚语',
                    RUS: '俄语',
                    TH: '泰语',
                    TRK: '土耳其语',
                    VIN: '越南语',
                    CHS: '中文(简体)',
                    CHT: '中文(繁體)',
                }
            }
        },
        created() {
            // this.$nextTick(() => {
            //     // 禁用右键
            //     document.oncontextmenu = new Function('event.returnValue=false')
            //     // 禁用选择
            //     document.onselectstart = new Function('event.returnValue=false')
            //     //禁止f12
            //     document.οnkeydοwn = new Function('event.returnValue=false')
            // })


        },
        mounted() {
            this.GetPhotoTransCount()
            this.GetUserPhotoTrans()
            let that = this
            // 3.监听 iframe 发送的 message 事件，从中获取精修结果        
            window.addEventListener('message', function(e) {
                const {
                    name,
                    all
                } = e.data;

                if (e.origin === 'https://www.xiangjifanyi.com' && name === 'XJ_IMAGE_EDITOR_URL') {
                    HTTP.updataPhotoTrans({
                        RequestId: e.data.requestId,
                        SslUrl: e.data.url
                    }).then(res => {
                        that.view_info.SslUrl = e.data.url
                        // 更新图片翻译列表
                        that.GetUserPhotoTrans()

                    })
                }
            });
        },
        methods: {
            // 下载图片

            downloadIamge: function(imgsrc, name) {
                //下载图片地址和图片名
                var image = new Image();
                // 解决跨域 Canvas 污染问题
                image.setAttribute("crossOrigin", "anonymous");
                image.onload = function() {
                    var canvas = document.createElement("canvas");
                    canvas.width = image.width;
                    canvas.height = image.height;
                    var context = canvas.getContext("2d");
                    context.drawImage(image, 0, 0, image.width, image.height);
                    var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据'
                    var a = document.createElement("a"); // 生成一个a元素
                    var event = new MouseEvent("click", {
                        bubbles: true,
                        cancelable: true,
                        view: window
                    }); // 创建一个单击事件
                    a.download = name || "photo"; // 设置图片名称
                    a.href = url; // 将生成的URL设置为a.href属性
                    a.dispatchEvent(event); // 触发a的单击事件
                };
                image.src = imgsrc;
            },
               
            // 复制到剪辑版
            copy: function(url){
            	navigator.clipboard.writeText(url).then(res=>{
            		this.$message.success('复制成功')
            	})
            },

            // 图片上传成功
            uploadPhotoSuccess: function(response, file, fileList) {
                if (response.Code == 200) {
                    this.params.address = response.Data.Url
                    this.$message.success("图片上传成功")
                }
            },
            // 上传失败
            uploadVideoError(e) {
                this.$message.error('上传失败', 'error')
            },
            // 个数超出
            uploadExceed: function(files, fileList) {
                if (fileList.length == 1) {
                    this.$message.error("最多上传一张图片，请清除上一张图片后再上传。")
                }
            },

            // 上传前调用
            uploadSuccess: function(file) {
                // 截取上传文件的后缀名
                let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
                // const isLt2M = file.size / 1024 / 1024 < 2;
                // 判断文件名的类型

                const ext1 = fileType === 'jpg'
                const ext2 = fileType === 'png'
                const ext3 = fileType === 'jpeg'

                if (!ext1 && !ext2 && !ext3) {
                    this.$message({
                        message: '请上传jpg、png、jpeg类型图片文件！',
                        type: 'info',
                        offset: 80
                    })
                    return false
                } else {
                    return true
                }

            },

            handleRemove: function(file, fileList) {
                this.params.address = ''
            },

            // 获取photo翻译可用次数
            GetPhotoTransCount: function() {
                HTTP.getPhotoCount().then(res => {
                    this.userinfo = res.data
                })
            },
            // 创建图片翻译
            CreatePhotoTrans: function() {
                this.fullscreenLoading = true
                HTTP.createPhotoTrans(this.params).then(res => {
                    // 成功取消遮挡
                    this.fullscreenLoading = false
                    this.$message.success(res.message)
                    this.GetUserPhotoTrans()
                    this.dialogFormVisible = false
                    // 更新使用次数
                    this.GetPhotoTransCount()

                }).finally(err => {
                    this.fullscreenLoading = false
                })
            },
            // 获取图片翻译记录
            GetUserPhotoTrans: function() {
                HTTP.userPhotoList(this.photo_params).then(res => {
                    this.data = res
                })
            },

            // 查看图片
            ViewPhoto: function(row) {
                this.view_info = row
                this.edit = true
            },


            iframeOnload: function(key) {
                var iframe = document.querySelector('#xiangji-image-editor');
                iframe.contentWindow.postMessage({
                        name: "XJ_IMAGE_EDITOR_REQUESTIDS",
                        requestIds: [key]
                    },
                    'https://www.xiangjifanyi.com'
                );
            },


            handleSizeChange(value) {
                this.video_params.limit = value
                this.GetUserPhotoTrans()
            },
            handleCurrentChange(value) {
                this.video_params.page = value
                this.GetUserPhotoTrans()
            },

        }
    }
</script>

<style scoped>
    .el-row {
        text-align: left;
    }

    .user-box {
        /* background: #fff; */

        height: 100px;
        padding: 20px;
        margin-bottom: 20px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    }

    .el-row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .box-card {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: #000000d9;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum";
        position: relative;
        background: #fff;
        /* background: rgba(232,250,232,.4); */
        border-radius: 10px;
        height: calc(100vh - 100px);
    }


    /deep/.dialog-box-card {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: #000000d9;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum";
        position: relative;
        background: #fff;
        border-radius: 10px;
        min-width: 520px;
        max-width: 520px;
        margin: 0 auto;
    }

    /deep/ .el-dialog__header {
        padding: 16px 24px;
        color: #000000d9;
        border-bottom: 1px solid #f0f0f0;
    }

    /* .el-upload--picture-card {
        width: 400px !important;
        height: 300px;
        line-height: 300px;
        background-color: pink;
    } */

    /deep/ .el-upload-dragger {
        background-color: #fff;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        box-sizing: border-box;
        width: calc(43vh - 30px);
        text-align: center;
        position: relative;


    }


    .centent {
        margin: 15px 0px;
        height: 800px;
        /* background-color: #008A00; */
    }

    .centent .centent-left {
        min-width: 350px;
        width: 350px;
        /* background-color: #fff; */
        height: auto;

    }

    .centent .centent-left .box {
        height: auto;
        padding: 15px;
        border-radius: 10px;
        background-color: #fff;
        /* background: rgba(232,250,232,.4); */
        border-bottom: 2px solid #EBEEF5;
        box-sizing: border-box;
    }

    .img-list {
        cursor: pointer;
        transition: all 0.3s linear;
    }

    .img-list:hover {
        transform: scale(1.1);
    }
</style>
