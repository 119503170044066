<template>

	<el-container class="body">

		<el-header>

			<el-menu style="display: flex; align-items: center;" :default-active="$route.path" unique-opened class="el-menu-demo"
			 mode="horizontal" background-color="#545c64" text-color="#fff" router active-text-color="#ffd04b">

				<div style="max-height: 60px; align-items: center;">
					<el-image style="width: 150px; height: 50px; border: 0px; margin-right: 10px;" :src="logo_url" fit="fit"></el-image>
				</div>

				<el-menu-item index="/admin/dashboard">
					<i class="el-icon-setting"></i>
					<span slot="title">工作台</span>
				</el-menu-item>
				
				<!-- <el-menu-item index="/admin/control">
					<i class="el-icon-setting"></i>
					<span slot="title">控制面板</span>
				</el-menu-item> -->

				
				<el-menu-item index="/admin/userlist">
					
					<i class="el-icon-user-solid"></i>
					<span slot="title">用户管理</span>
				</el-menu-item>
				
				<el-menu-item index="/admin/package">
					<i class="el-icon-present"></i>
					<span slot="title">套餐管理</span>
				</el-menu-item>
                
                <el-menu-item index="/admin/peiyin">
                    <i class="el-icon-microphone"></i>
                    <span slot="title">配音管理</span>
                </el-menu-item>
				
				<el-menu-item index="/admin/packageRecord">
					<i class="el-icon-shopping-cart-full"></i>
					<span slot="title">购买管理</span>
				</el-menu-item>
				
				
				
				<el-menu-item index="/admin/notice">
					<i class="el-icon-bell"></i>
					<span slot="title">公告管理</span>
				</el-menu-item>
				
				
						
				<el-menu-item index="/admin/video">
					<i class="el-icon-menu"></i>
					<span slot="title">视频管理</span>
				</el-menu-item>
                
                <el-menu-item index="/admin/video">
                	<i class="el-icon-picture-outline"></i>
                	<span slot="title">图片管理</span>
                </el-menu-item>
				
				<el-menu-item index="/admin/recharge">
					<i class="el-icon-s-order"></i>
					<span slot="title">充值账变</span>
				</el-menu-item>
				
				<el-menu-item index="/admin/loglog">
					<i class="el-icon-s-data"></i>
					<span slot="title">登录日志</span>
				</el-menu-item>
				
				
				
				

			</el-menu>

			<div style="align-items: center; display: flex;">
				<el-dropdown style="display: flex; padding: 0px 10px;" @command="handleCommand">
					<el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
					<el-dropdown-menu slot="dropdown" style="margin-top: 0px;">
						<el-dropdown-item command="logout">退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>

		</el-header>

		<el-main>
			<router-view></router-view>
		</el-main>
		</div>

	</el-container>

</template>

<script>
	import HTTP from '@/request/admin/api'
	export default {
		data() {
			return {
				viewLabel: false,
				isCollapse: false,
				logo_url: require("@/assets/images/logo.png"),
				tx_url: require("@/assets/images/aa.png"),
				params: {
					old_pass: '',
					new_pass: '',
					change_pass: ''
				}
			}
		},
		methods: {
			setCollapse: function(val) {
				this.isCollapse = !this.isCollapse
			},
			jump: function(name) {
				this.$router.push({
					name: name
				})
			},
			submit: function() {
				HTTP.revisePassword(this.params).then(res => {
					this.$message.success(res.message)
					this.viewLabel = false
				})
			},
			handleCommand(command) {
				if(command == 'pass'){
					this.viewLabel = true
				}else{
					sessionStorage.clear()
					this.$message.success("退出成功!")
					this.$router.push({
						name: 'Login'
					})
				}
			}
		}
	}
</script>

<style scoped>
	.body {
		height: 100%;
		min-width: 1400px;
	}

	.el-header {
		background: #545c64;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
</style>
