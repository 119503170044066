<template>

    <el-row :gutter="15" style="min-width: 1200px;" v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="配音合成中..."
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">


        <el-col :span="10">


            <el-row>
                <el-col>
                    <el-card class="box-card" style="height: 610px;">
                        <div slot="header" class="clearfix">
                            <div style="display: flex; justify-content: space-between;">
                                <span>文字转语音配音专区</span>

                                <div>

                                    <span v-if="userinfo.meal_package == null" style="color: red;"> 没有套餐</span>
                                    <span v-else style="color: red;">您的套餐:{{ userinfo.meal_package.name}}</span>
                                    
                                    <span style="margin-left: 15px;">到期时间:{{ userinfo.voice_expire_time}}</span>
                                </div>

                            </div>
                        </div>

                        <div>

                            <el-alert :closable="false" style="margin-bottom: 10px;" title="注意: 输入内容请勿输入特殊字符, 最大输入长度1500字, 非会员只能试用5次. 购买会员无次数限制."
                                type="info" show-icon>
                            </el-alert>



                            <div class="centent">



                                <div>
                                    <el-input v-model="voice_post_params.text" type="textarea" :rows="8" @blur="blurEvent"
                                        placeholder="输入文字生成配音" maxlength="1500" show-word-limit>
                                    </el-input>
                                </div>

                                <div style="margin: 15px 0; ">
                                    <div>
                                        <span>当前配音员：{{ voice_post_params.voice_pid || '未选择' }}</span>
                                        <el-button style="margin-left: 15px;" type="success" size="mini" @click="view = true">选择配音员</el-button>
                                    </div>
                                </div>


                                <div style="display: flex; margin-bottom: 20px; align-items: center;">
                                    <span style="width: 60px;">语速：</span>
                                    <el-slider style="width: 100%; margin-left: 15px;" :marks="speech_marks" :min="0"
                                        :max="100" v-model="voice_post_params.speech_rate" :step="10" show-stops>
                                    </el-slider>
                                </div>

                                <div style="display: flex; margin-bottom: 20px; align-items: center;">
                                    <span style="width: 60px;">语调：</span>
                                    <el-slider style="width: 100%; margin-left: 15px;" :marks="marks" v-model="voice_post_params.pitch_rate"
                                        :step="100" show-stops>
                                    </el-slider>
                                </div>

                                <div style="display: flex; margin-bottom: 20px; align-items: center;">
                                    <span style="width: 60px;">音量：</span>
                                    <el-slider style="width: 100%; margin-left: 15px;" :marks="marks" v-model="voice_post_params.volume"
                                        :step="1" show-stops>
                                    </el-slider>
                                </div>



                                <div style="display: flex; justify-content: space-between; margin: 15px 0; ">
                                    <div>
                                        <span>插入停顿：</span>
                                        <el-button-group>
                                            <el-button size="mini" @click="InsertStop('[p200]')">0.2s</el-button>
                                            <el-button size="mini" @click="InsertStop('[p300]')">0.3s</el-button>
                                            <el-button size="mini" @click="InsertStop('[p500]')">0.5s</el-button>
                                            <el-button size="mini" @click="InsertStop('[p1000]')">1s</el-button>
                                        </el-button-group>
                                    </div>

                                    <el-button size="mini" @click="CreateAiVoice">生成配音</el-button>
                                </div>


                            </div>


                        </div>
                    </el-card>
                </el-col>

                <el-col style="margin-top: 15px;">
                    <!-- height: calc(100vh - 115px - 500px); -->
                    <el-card class="box-card" style="height: calc(100vh - 115px - 610px); padding-bottom: 30px;">
                        <div slot="header" class="clearfix">
                            <div style="display: flex; justify-content: space-between;">
                                <span>常见问题</span>
                            </div>
                        </div>

                        <div>
                            <h4 style="margin: 0; padding: 0;">1、长时间未返回音频数据？</h4>
                            <span>1500字的文本一般在1-2分钟内返回音频，如一直过长时间未返回音频数据，可能是当前时间段合成人数过多，请稍等几分钟后再尝试，切勿频繁点击生成配音！</span>
                            <h4 style="margin: 0; padding: 0;">1、如何购买会员？</h4>
                            <span>可点击右上方购买翻译会员， 也可联系客服进行购买！</span>
                        </div>
                    </el-card>
                </el-col>
            </el-row>





        </el-col>

        <el-col :span="14">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <div style="display: flex; justify-content: space-between;">
                        <span><i class="el-icon-microphone"></i> 配音记录</span>
                    </div>
                </div>

                <div>
                    <el-table :data="aiRecord.data" style="width: 100%" >
                        <el-table-column prop="create_time" label="配音时间" width="160">

                        </el-table-column>
                        <el-table-column prop="voice_pid.voice_name" label="配音员" width="180">

                        </el-table-column>
                        <el-table-column prop="name" fixed label="音频" width="350">
                            <template slot-scope="scope">

                                <mini-audio style="margin: 0; box-shadow: none;" :audio-source="scope.row.os_address"></mini-audio>

                            </template>
                        </el-table-column>
                        <el-table-column prop="date" label="合成内容" width="180">
                            <template slot-scope="scope">
                                <el-popover placement="top-start"  width="300" trigger="hover" :content="scope.row.text">
                                    <span slot="reference">{{ scope.row.text.substring(0,10) }}...</span>
                                </el-popover>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" fixed="right" width="120" align="center">
                            <template slot-scope="scope">
                                <el-button icon="el-icon-upload" type="infor" size="mini" @click="GetMP3Down(scope.row.os_address)">下载MP3</el-button>
                                <!-- <el-button style="margin: 0 15px;" type="danger" size="mini" @click="GetUserVideoEdit(scope.row.id)">删除</el-button> -->
                            </template>
                        </el-table-column>
                    </el-table>

                    <div style="position: absolute; bottom: 0; padding: 15px 0 0 0;">

                        <el-pagination small @size-change="record_handleSizeChange" @current-change="record_handleCurrentChange"
                            :current-page="this.params.page" :page-sizes="[12, 24, 50, 100]" :page-size="10"
                            layout="total, prev, pager, next" :total="aiRecord.total" style="margin-top:15px; margin-bottom: 15px; position: relative;">
                        </el-pagination>
                    </div>

                </div>
            </el-card>
        </el-col>



        <el-drawer title="配音员选择" :visible.sync="view" size="70%">

            <!-- background-color: #2C3E50; -->
            <div style=" height: 100%; ">
                <div style="padding: 0 20px; height: 100%;">

                    <el-tabs v-model="activeName_view" type="card" style="height: auto;">
                        <el-tab-pane label="配音员列表" name="first">
                            <el-table :data="voicelist.data" style="width: 100%">
                                <el-table-column prop="voice_name" label="配音员" width="260">
                                </el-table-column>
                                <el-table-column prop="desc" label="描述" width="260">
                                </el-table-column>

                                <el-table-column prop="name" label="音频">
                                    <template slot-scope="scope">
                                        <mini-audio style="margin: 0; box-shadow: none;" :audio-source="scope.row.os_url"></mini-audio>
                                    </template>
                                </el-table-column>

                                <el-table-column label="操作" fixed="right" width="200" align="center">
                                    <template slot-scope="scope">
                                        <el-button style="" type="primary" size="mini" @click="SetVoice(scope.row)">选择</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>

                            <div style="position: relative; ">

                                <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                    :current-page="this.voice_params.page" :page-sizes="[12, 24, 50, 100]" :page-size="12"
                                    layout="total, prev, pager, next" :total="voicelist.total" style="margin-top:15px; margin-bottom: 15px; position: relative;">
                                </el-pagination>
                            </div>

                        </el-tab-pane>
                        

                    </el-tabs>

                </div>
            </div>

        </el-drawer>



    </el-row>

</template>

<script>
    import HTTP from '@/request/api'

    export default {
        data() {
            return {

                activeName_view: 'first',
                fullscreenLoading: false,
                params: {
                    page: 1,
                    limit: 10
                },
                data: [],
                voicelist: [],
                voice_post_params: {
                    'volume': 70,
                    'pitch_rate': 50,
                    'speech_rate': 50,
                    'text': '',
                    'id': '',
                    'voice_pid': ''
                },
                marks: {
                    0: '低',
                    50: '中',
                    100: '高'
                },
                speech_marks: {
                    0: '0.5x',
                    50: '1.0x',
                    100: '1.5x',
                },
                view: false,
                voice_params: {
                    page: 1,
                    limit: 12
                },
                userinfo: "",
                blurIndex: null, // 记录光标位置
                aiRecord: []
            }
        },
        created() {

        },
        mounted() {
            this.GetAiRecord()
            this.GetAiVoicelist()
            this.GetUserInfo()
        },
        methods: {
            // 获取合成列表
            GetAiRecord: function() {
                HTTP.getAiList(this.params).then(res => {
                    this.aiRecord = res
                })
            },
            // 获取光标
            blurEvent: function(e) {
                this.blurIndex = e.srcElement.selectionStart
            },
            // 添加停留
            InsertStop: function(text) {
                let index = this.blurIndex
                let str = this.voice_post_params.text
                this.voice_post_params.text = str.slice(0, index) + text + str.slice(index)
            },

            // 创建翻译
            CreateAiVoice: function() {
                this.fullscreenLoading = true
                HTTP.CreateAiVoice(this.voice_post_params).then(res => {
                    this.GetAiRecord()
                    this.$message.success(res.message)
                    this.fullscreenLoading = false
                }).finally(err => {
                    this.fullscreenLoading = false
                })
            },
            // 下载配音
            GetMP3Down: function(url){
                window.open(url, '_blank')
            },
            // 获取本人配音套餐
            GetUserInfo: function() {
                // 新
                HTTP.userinfo().then(res => {
                    this.userinfo = res.data
                })
            },

            // 获取配音列表
            GetAiVoicelist: function() {
                HTTP.getAiVoicelist(this.voice_params).then(res => {
                    this.voicelist = res
                })
            },

            // 选择配音员
            SetVoice: function(row) {
                this.view = false
                this.voice_post_params.voice_pid = row.voice_name
                this.voice_post_params.id = row.id
            },

            handleSizeChange(value) {
                this.voice_params.limit = value
                this.GetAiVoicelist()
            },
            handleCurrentChange(value) {
                this.voice_params.page = value
                this.GetAiVoicelist()
            },
            
            record_handleSizeChange(value) {
                this.params.limit = value
                this.GetAiRecord()
            },
            record_handleCurrentChange(value) {
                this.params.page = value
                this.GetAiRecord()
            },
            
        }
    }
</script>

<style scoped>
    .el-row {
        text-align: left;
    }

    .user-box {
        /* background: #fff; */

        height: 100px;
        padding: 20px;
        margin-bottom: 20px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    }

    .el-row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .box-card {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: #000000d9;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum";
        position: relative;
        background: #fff;
        /* background: rgba(232,250,232,.4); */
        border-radius: 10px;
        height: calc(100vh - 100px);
    }


    /deep/.dialog-box-card {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: #000000d9;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum";
        position: relative;
        background: #fff;
        border-radius: 10px;
        min-width: 520px;
        max-width: 520px;
        margin: 0 auto;
    }

    /deep/ .el-dialog__header {
        padding: 16px 24px;
        color: #000000d9;
        border-bottom: 1px solid #f0f0f0;
    }

    /* .el-upload--picture-card {
        width: 400px !important;
        height: 300px;
        line-height: 300px;
        background-color: pink;
    } */

    /deep/ .el-upload-dragger {
        background-color: #fff;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        box-sizing: border-box;
        width: calc(43vh - 30px);
        text-align: center;
        position: relative;


    }


    .centent {
        margin: 15px 0px;
        height: 800px;
        /* background-color: #008A00; */
    }

    .centent .centent-left {
        min-width: 350px;
        width: 350px;
        /* background-color: #fff; */
        height: auto;

    }

    .centent .centent-left .box {
        height: auto;
        padding: 15px;
        border-radius: 10px;
        background-color: #fff;
        /* background: rgba(232,250,232,.4); */
        border-bottom: 2px solid #EBEEF5;
        box-sizing: border-box;
    }

    .img-list {
        cursor: pointer;
        transition: all 0.3s linear;
    }

    .img-list:hover {
        transform: scale(1.1);
    }

    /deep/ .vueAudioBetter {
        margin: 0;
    }
</style>
