<!-- <template>

	<el-row style="min-width: 1200px;">

		<el-row :gutter="15">
			<el-col :span="12">
				<el-card style="height: 140px; position: relative;">
					<div style="width: 110px; text-align: center; position: relative; float: left;">
						<el-avatar style="width: 100px; height: 100px; background-color: #fff; border: 1px solid #eee;" :src="tx_url"></el-avatar>
					</div>
					<div style="width: 280px; float: left; font-size: 14px; font-weight: 1000;">
						<div style="padding: 20px;">
							<span>欢迎您管理员！{{ userinfo.username }}</span>
						</div>
						<div style="padding: 10px 0px 20px 20px;">
							<span>欢迎登录换绑管理后台</span>
						</div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card style="height: 140px; position: relative;">

					<el-col :span="8">
						<div>

							<div style="padding: 20px; min-width: 150px;">
								<i class="el-icon-user-solid" style="font-size: 60px; color: #F4A460;"></i>

								<div style="width: 90px; height: 60px; float: right; font-weight: 600;">
									<span style="font-size: 18px; float: left; width: 90px; padding: 5px 0px 10px 10px;">{{ userinfo.user_count }}</span>
									<span style="font-size: 14px; float: left; width: auto; padding: 0px 0px 10px 10px;">用户数量</span>
								</div>

							</div>

						</div>
					</el-col>

					<el-col :span="8">
						<div>
							<div style="padding: 20px; min-width: 150px;">
								<i class="el-icon-s-platform" style="font-size: 60px; color: #409EFF;"></i>
								<div style="width: 90px; height: 60px; float: right; font-weight: 600;">
									<span style="font-size: 18px; float: left; width: 90px; padding: 5px 0px 10px 10px;">{{ userinfo.recharge_count }}</span>
									<span style="font-size: 14px; float: left; width: auto; padding: 0px 0px 10px 10px;">充值数量</span>
								</div>
							</div>

						</div>
					</el-col>

					<el-col :span="8">
						<div>

							<div style="padding: 20px; min-width: 150px;">
								<i class="el-icon-s-marketing" style="font-size: 60px; color: #42B983;"></i>
								<div style="width: 90px; height: 60px; float: right; font-weight: 600;">
									<span style="font-size: 18px; float: left; width: 90px; padding: 5px 0px 10px 10px;">{{ userinfo.bankcard_count }}</span>
									<span style="font-size: 14px; float: left; width: auto; padding: 0px 0px 10px 10px;">换绑数量</span>
								</div>
							</div>

						</div>
					</el-col>


				</el-card>
			</el-col>
		</el-row>

		<el-row :gutter="15">
			<el-col :span="24">
				<el-row :gutter="15">
					<el-col :span="8">
						<el-card style="height: auto;">
							<div>
								<div id="main2" style="height:600px;margin-top:10px;"></div>
							</div>
						</el-card>
					</el-col>

					<el-col :span="16">
						<el-card style="height: auto;">
							<div>
								<div id="main" style="height:600px;margin-top:10px;"></div>
							</div>
						</el-card>
					</el-col>
				</el-row>

			</el-col>
		</el-row>
	</el-row>

</template>

<script>

	import HTTP from '@/request/admin/api'

	export default {
		data() {
			return {
				tx_url: require("@/assets/images/aa.png"),
				params: {
					page: 1,
					limit: 10
				},
				result: [],
				userinfo: '',
				banner: [],
			}
		},
		created() {

		},
		mounted() {
			this.GetUserInfo()
		},
		methods: {

			GetUserInfo: function() {
				// 新
				HTTP.admininfo().then(res=>{
					this.userinfo = res.data
					this.echartsInit()
					this.echartsInit2()
				})
			},
			echartsInit2: function() {

				var myChart2 = this.$echarts.init(document.getElementById('main2'))
				var option2 = {
					title: {
						text: '用户任务比例',
						subtext: 'Baidu Data',
						left: 'left'
					},
					tooltip: {
						trigger: 'item'
					},
					legend: {
						orient: 'vertical',
						left: 'right'
					},
					series: [{
						name: '发布任务数量',
						type: 'pie',
						radius: '60%',
						data: this.userinfo.charts_data,
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}]
				}

				myChart2.setOption(option2, true, false)
			},


			echartsInit: function() {
				var myChart = this.$echarts.init(document.getElementById('main'));

				var option = {

					title: {
						text: '用户任务提交走势',
					},
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					toolbox: {
						feature: {
							saveAsImage: {}
						}
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: this.userinfo.charts_baidu_data.time
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						name: '发布条数',
						type: 'line',
						stack: 'Total',
						data: this.userinfo.charts_baidu_data.data,
					}, ]

				}

				myChart.setOption(option, true, false)
			},
		}
	}
</script>

<style scoped>
	.el-row {
		text-align: left;
	}

	.user-box {
		background: #fff;
		height: 100px;
		padding: 20px;
		margin-bottom: 20px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
	}

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.el-col {
		border-radius: 4px;
	}

	.box-card {
		height: 205px;
	}


	.bg-purple-dark {
		background: #99a9bf;
	}

	.bg-purple {
		background: #d3dce6;
		height: 150px;
	}

	.bg-purple-light {
		background: #e5e9f2;
	}

	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}

	.row-bg {
		padding: 10px 0;
		background-color: #f9fafc;
	}

	.el-carousel__item h3 {
		color: #475669;
		font-size: 14px;
		opacity: 0.75;
		line-height: 250px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #d3dce6;
	}
</style>
 -->
 
 
 
 
 <template>
 
 
 
 	<el-row style="min-width: 1200px;">
 
 		<meta name="referrer" content="never">
 
 		<el-row :gutter="15">
 			<el-col :span="10">
 				<el-card class="box-card" style="height: 140px; position: relative;">
 					<div style="width: 110px; text-align: center; position: relative; float: left;">
 						<el-avatar style="width: 100px; height: 100px;" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
 						<!-- <el-avatar style="width: 100px; height: 100px; background-color: #eee; border: 1px solid #eee;" :src="tx_url"></el-avatar> -->
 					</div>
 					<div style="width: 250px; float: left; font-size: 14px; font-weight: 1000;">
 						<div style="padding: 20px;">
 							<span>欢迎您管理员！{{ userinfo.username }}</span>
 						</div>
 						<div style="padding: 10px 0px 20px 20px;">
 							<span>欢迎登录云象视频字幕翻译管理系统</span>
 						</div>
 					</div>
 					<!-- <div style="width: 230px; height: 100px; float: right; font-size: 14px; font-weight: 1000; border: #eee 1px solid; border-radius: 13px; margin-right: 15px;">
 						<div style="padding: 20px 20px 10px 20px">
 							<i class="el-icon-wallet" style="color: #42B983;margin-right: 5px;"></i>
 							<span>钱包余额</span>
 						</div>
 						<div style="padding: 10px 0px 20px 20px;">
 							<span style="font-size: 20px; float: left; padding: 0 10px;">{{ userinfo.balance }}</span>
 							<el-button size="mini" style="float: right; margin-right: 15px;" type="primary" icon="el-icon-wallet" @click="$router.push({name: 'Pay'})">充值</el-button>
 						</div>
 					</div> -->
 				</el-card>
 			</el-col>
 			<el-col :span="14">
 				<el-card class="box-card" style="height: 140px; position: relative;">
					
					
					<el-col :span="6">
						<div>
					
							<div style="padding: 20px; min-width: 150px;">
								<i class="el-icon-user-solid" style="font-size: 60px; color: #99A9BF;"></i>
					
								<div style="width: 90px; height: 60px; float: right; font-weight: 600;">
									<span style="font-size: 18px; float: left; width: 90px; padding: 5px 0px 10px 10px;">{{ userinfo.user_count }}</span>
									<span style="font-size: 14px; float: left; width: auto; padding: 0px 0px 10px 10px;">用户数量</span>
								</div>
					
							</div>
					
						</div>
					</el-col>
					
					
					<el-col :span="6">
						<div>
							<div style="padding: 20px; min-width: 150px;">
								<i class="el-icon-food" style="font-size: 60px; color: #409EFF;"></i>
								<div style="width: 90px; height: 60px; float: right; font-weight: 600;">
									<span style="font-size: 18px; float: left; width: 90px; padding: 5px 0px 10px 10px;">{{ userinfo.pay_count }}</span>
									<span style="font-size: 14px; float: left; width: auto; padding: 0px 0px 10px 10px;">本月收入</span>
								</div>
							</div>
					
						</div>
					</el-col>
 
 					<el-col :span="6">
 						<div>
 							<div style="padding: 20px; min-width: 150px;">
 								<i class="el-icon-mobile-phone" style="font-size: 60px; color: #42B983;"></i>
 								<div style="width: 90px; height: 60px; float: right; font-weight: 600;">
 									<span style="font-size: 18px; float: left; width: 90px; padding: 5px 0px 10px 10px;">{{ userinfo.login_count }}</span>
 									<span style="font-size: 14px; float: left; width: auto; padding: 0px 0px 10px 10px;">登录次数</span>
 								</div>
 							</div>
 
 						</div>
 					</el-col>
 
 					<el-col :span="6">
 						<div>
 
 							<div style="padding: 20px; min-width: 150px;">
 								<i class="el-icon-video-camera-solid" style="font-size: 60px; color: #F4A460;"></i>
 								<div style="width: 90px; height: 60px; float: right; font-weight: 600;">
 									<span style="font-size: 18px; float: left; width: 90px; padding: 5px 0px 10px 10px;">{{ userinfo.video_count }}</span>
 									<span style="font-size: 14px; float: left; width: auto; padding: 0px 0px 10px 10px;">翻译次数</span>
 								</div>
 							</div>
 
 						</div>
 					</el-col>
 
 
 				</el-card>
 			</el-col>
 		</el-row>
 
 		<el-row :gutter="15">
 			<el-col :span="24">
 				<el-row :gutter="15">
 					<el-col :span="8">
 						<el-card class="box-card" style="height: auto;">
 							<div>
 								<div id="main2" style="height:600px;margin-top:10px;"></div>
 							</div>
 						</el-card>
 					</el-col>
 
 					<el-col :span="16">
 						<el-card class="box-card" style="height:650px;">
							<div id="main" style="height:600px;margin-top:10px;"></div>
 						</el-card>
 					</el-col>
 

 				</el-row>
 				
 				
 			</el-col>
 		</el-row>
 	</el-row>
 
 </template>
 
 <script>
 	import HTTP from '@/request/admin/api'
 
 	export default {
 		data() {
 			return {
 				tx_url: require("@/assets/images/yunxiang.png"),
 				dy_url: require("@/assets/images/dy.png"),
 				play_url: 'https://aweme.snssdk.com/aweme/v1/play/?video_id=v0d00fg10000cdp03ujc77u1a3qpcm30&ratio=720p&line=0',
 				params: {
 					page: 1,
 					limit: 10
 				},
 				result: [],
 				userinfo: '',
 				dy_params: '',
 				dy_data: '',
 				notice_params: {
 					page: 1,
 					limit: 5
 				},
 				notice: [],
 				dialogFormVisible: false,
 				notice_data: ""
 			}
 		},
 		created() {
 
 		},
 		mounted() {
 			this.GetUserInfo()
 		},
 		methods: {

 			GetUserInfo: function() {
 				// 新
 				HTTP.admininfo().then(res => {
 					this.userinfo = res.data
 					this.echartsInit()
 					this.echartsInit2()
 				})
 			},
 			errorHandler() {
 				return true
 			},
 			echartsInit2: function() {
 
 				var myChart2 = this.$echarts.init(document.getElementById('main2'))
 				var option2 = {
 					title: {
 						text: '使用比例',
 						subtext: 'Data',
 						left: 'left'
 					},
 					tooltip: {
 						trigger: 'item'
 					},
 					legend: {
 						orient: 'vertical',
 						left: 'right'
 					},
 					series: [{
 						name: '比例数量',
 						type: 'pie',
 						radius: '60%',
 						data: this.userinfo.charts_data,
 						emphasis: {
 							itemStyle: {
 								shadowBlur: 10,
 								shadowOffsetX: 0,
 								shadowColor: 'rgba(0, 0, 0, 0.5)'
 							}
 						}
 					}]
 				}
 
 				myChart2.setOption(option2, true, false)
 			},
 
 
 			echartsInit: function() {
 				var myChart = this.$echarts.init(document.getElementById('main'));
 
 				var option = {
 
 					title: {
 						text: '用户翻译走势',
 					},
 					tooltip: {
 						trigger: 'axis'
 					},
 					legend: {
 						data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
 					},
 					grid: {
 						left: '3%',
 						right: '4%',
 						bottom: '3%',
 						containLabel: true
 					},
 					toolbox: {
 						feature: {
 							saveAsImage: {}
 						}
 					},
 					xAxis: {
 						type: 'category',
 						boundaryGap: false,
 						data: this.userinfo.charts_baidu_data.time
 					},
 					yAxis: {
 						type: 'value'
 					},
 					series: [{
 						name: '翻译次数',
 						type: 'line',
 						stack: 'Total',
 						data: this.userinfo.charts_baidu_data.data,
 					}, ]
 
 				}
 
 				myChart.setOption(option, true, false)
 			},
 		}
 	}
 </script>
 
 <style scoped>
 	.el-row {
 		text-align: left;
 	}
 
 	.user-box {
 		background: #fff;
 		height: 100px;
 		padding: 20px;
 		margin-bottom: 20px;
 		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
 	}
 
 	.el-row {
 		margin-bottom: 20px;
 
 		&:last-child {
 			margin-bottom: 0;
 		}
 	}
 
 	.el-col {
 		border-radius: 4px;
 	}
 
 	.box-card {
 		height: 205px;
 	}
 
 	.box-card {
 		box-sizing: border-box;
 		margin: 0;
 		padding: 0;
 		color: #000000d9;
 		font-size: 14px;
 		font-variant: tabular-nums;
 		line-height: 1.5715;
 		list-style: none;
 		font-feature-settings: "tnum";
 		position: relative;
 		background: #fff;
 		border-radius: 10px;
 	}
 
 	.bg-purple-dark {
 		background: #99a9bf;
 	}
 
 	.bg-purple {
 		background: #d3dce6;
 		height: 150px;
 	}
 
 	.bg-purple-light {
 		background: #e5e9f2;
 	}
 
 	.grid-content {
 		border-radius: 4px;
 		min-height: 36px;
 	}
 
 	.row-bg {
 		padding: 10px 0;
 		background-color: #f9fafc;
 	}
 
 	.el-carousel__item h3 {
 		color: #475669;
 		font-size: 14px;
 		opacity: 0.75;
 		line-height: 250px;
 		margin: 0;
 	}
 
 	.el-carousel__item:nth-child(2n) {
 		background-color: #99a9bf;
 	}
 
 	.el-carousel__item:nth-child(2n+1) {
 		background-color: #d3dce6;
 	}
 
 
 	.search {
 		position: relative;
 		text-align: center;
 		width: 100%;
 		display: flex;
 		align-items: center;
 		margin: 0 auto;
 		margin: 70px auto 0;
 		border-radius: 5px;
 		/* background: #eee; */
 		background-color: rgb(244, 248, 250);
 		;
 		cursor: pointer;
 	}
 
 	.search .select-wrap {
 		position: relative;
 		display: flex;
 		align-items: center;
 		color: #333;
 		font-size: 18px;
 		padding: 15px 20px;
 	}
 
 	.search .select-wrap .logo {
 		width: 24px;
 		padding-right: 10px;
 		align-items: center;
 	}
 
 	.search .search-input-wrap {
 		position: relative;
 		flex: 1;
 
 	}
 
 	.search .search-input-wrap .search-input {
 		border: none;
 		color: #333;
 		outline: none;
 		padding: 15px;
 		font-size: 18px;
 		width: 100%;
 		box-sizing: border-box;
 	}
 
 	.search .search-btn {
 		display: flex;
 		align-items: center;
 		background: #CCC;
 		/* background: #E6A23C; */
 		/* background: rgb(244, 248, 250);; */
 		text-align: center;
 		color: #fff;
 		border: none;
 		height: 56.6px;
 		padding: 0 40px;
 		font-size: 18px;
 		cursor: pointer;
 		border-radius: 0 5px 5px 0;
 	}
 	
 	/deep/.dialog-box-card {
 		box-sizing: border-box;
 		margin: 0;
 		padding: 0;
 		color: #000000d9;
 		font-size: 14px;
 		font-variant: tabular-nums;
 		line-height: 1.5715;
 		list-style: none;
 		font-feature-settings: "tnum";
 		position: relative;
 		background: #fff;
 		border-radius: 10px;
 		min-width: 910px;
 		max-width: 910px;
 		margin: 0 auto;
 		height: auto;
 	}
 	
 	/deep/ .el-dialog__header {
 		padding: 16px 24px;
 		color: #000000d9;
 		border-bottom: 1px solid #f0f0f0;
 	}
 	
 </style>
 