<template>
	<div id="app">
		<router-view />
		<kefu></kefu>
	</div>
	
</template>

<script>
	import kefu from '@/views/kefu.vue'
	export default {
		name: 'app',
		components:{
			kefu
		}
	}
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: left;
		color: #2c3e50;
	}
	
</style>
