<template>

	<el-row style="min-width: 1200px;">

		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>AI配音管理</span>
			</div>

			<div>

				<el-alert :closable="false" style="margin-bottom: 10px;" title="管理所有用户配音列表" type="info" show-icon>
				</el-alert>

				<div>
					<el-input v-model="params.text" clearable style="width: 250px; margin-right: 10px;" size="small"
					 placeholder="请输入内容搜索" prefix-icon="el-icon-search"></el-input>
					<el-button size="small" icon="el-icon-search" @click="GetVoiceList">搜索一下</el-button>
                    <el-button type="success" size="small" icon="el-icon-search" @click="GetAuthLoginCode">更新认证信息</el-button>
				</div>


				<div>

					<el-table :data="data.data" height="600">
						<template slot="empty">
							<el-empty :image-size="200" description="暂未查询到数据哦!"></el-empty>
						</template>
						<el-table-column type="index"></el-table-column>
						<el-table-column prop="user_id.username" label="所属用户" align="center" width="200"></el-table-column>
                        
                        <el-table-column prop="date" label="合成内容" width="300">
                            <template slot-scope="scope">
                                <el-popover placement="top-start"  width="300" trigger="hover" :content="scope.row.text">
                                    <span slot="reference">{{ scope.row.text.substring(0,20) }}...</span>
                                </el-popover>
                            </template>
                        </el-table-column>
                        
						<el-table-column prop="os_address" label="云储存地址" align="center">
						</el-table-column>
						<el-table-column prop="create_time" label="创建日期" align="center" width="150">
						</el-table-column>

						<el-table-column label="操作" width="120" align="center">

							<template slot="header" slot-scope="scope">
								<el-button type="primary" icon="el-icon-refresh" size="mini" @click="GetVoiceList">刷新</el-button>
							</template>

							<template slot-scope="scope">
								<el-popconfirm title="确定要删除选中的数据吗？" @confirm="DelVoice(scope.row.id)" style="margin: 0px 15px;">
									<el-button slot="reference" type="danger" size="mini">删除</el-button>
								</el-popconfirm>
							</template>
						</el-table-column>

					</el-table>


					<el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="this.params.page"
					 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next" :total="data.total" style="margin-top:15px; margin-bottom: 15px; position: relative;">
					</el-pagination>
				</div>
                
                
                <el-dialog style="width: auto;" custom-class="dialog-box-card" title="配音授权扫码登录" :visible.sync="dialogFormVisible" append-to-body>
                    
                    <el-alert :closable="false" style="margin-bottom: 10px;" title="使用认证的微信扫码登录配音账号" type="info" show-icon>
                    </el-alert>
                
                    <div>
                        <el-image :src='info.url'></el-image>
                    </div>
                    
                    <div>
                        <span>请在{{ count }}秒内，使用微信扫码认证。过期请重新获取授权登录！</span>
                    </div>
                
                </el-dialog>
                

			</div>
		</el-card>


	</el-row>

</template>

<script>
	import HTTP from '@/request/admin/api'
	export default {
		data() {
			return {
				data: [],
				params: {
					page: 1,
					limit: 10,
					text: ''
				},
                dialogFormVisible: false,
                info: '',
                count: 60
			}
		},
		mounted() {
			this.GetVoiceList()
		},

		methods: {

			// 获取配音列表
			GetVoiceList: function() {
				HTTP.getVoiceList(this.params).then(res => {
					this.data = res
				})
			},

			// 删除配音
			DelVoice: function(id){
				HTTP.delVoice({id: id}).then(res=>{
					this.$message.success(res.message)
					this.GetVoiceList()
				})
			},
            
            // 发起登录二维码请求
            GetAuthLoginCode: function(){
                HTTP.aiLogin().then(res=>{
                    this.info = res.data
                    this.dialogFormVisible = true
                    
                    // 定时获取新任务状态
                    this.countDown = setInterval(() => {
                        
                        if(this.count < 1){
                            // 重新获取验证码
                            this.GetAuthLoginCode()
                            this.count = 60
                            clearInterval(this.countDown)
                        }else{
                            this.count -= 2
                            // 获取状态
                            HTTP.aiLoginState({scene_id:this.info.scene_id}).then(res=>{
                                if(res.message == 'ok'){
                                    clearInterval(this.countDown)
                                    this.dialogFormVisible = false
                                    this.$message.success("授权登录完成，请每隔6天认证一次，以免过期！")
                                }
                            })
                        }
                       
                    }, 2000);
                    
                })
            },

			handleSizeChange(value) {
				this.params.limit = value
				this.GetVoiceList()
			},
			handleCurrentChange(value) {
				this.params.page = value
				this.GetVoiceList()
			},
		}
	}
</script>

<style scoped>
	.el-row {
		text-align: left;
	}

	.user-box {
		background: #fff;
		height: 100px;
		padding: 20px;
		margin-bottom: 20px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
	}

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.box-card {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		color: #000000d9;
		font-size: 14px;
		font-variant: tabular-nums;
		line-height: 1.5715;
		list-style: none;
		font-feature-settings: "tnum";
		position: relative;
		background: #fff;
		border-radius: 10px;
	}

	/deep/.dialog-box-card {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		color: #000000d9;
		font-size: 14px;
		font-variant: tabular-nums;
		line-height: 1.5715;
		list-style: none;
		font-feature-settings: "tnum";
		position: relative;
		background: #fff;
		border-radius: 10px;
		min-width: 480px;
		max-width: 480px;
		margin: 0 auto;
		height: 620px;
	}

	/deep/ .el-dialog__header {
		padding: 16px 24px;
		color: #000000d9;
		border-bottom: 1px solid #f0f0f0;
	}
</style>
